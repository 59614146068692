import { useRecoilState } from "recoil";
import { appStateAtom } from "../recoil/atoms/appState";

export function useAnimate() {
  const [appState, setAppState] = useRecoilState(appStateAtom)  
  
  const animateBasket = () => {
    setAppState({...appState, animateBasket: true})
    setTimeout(() => {
      setAppState({...appState, animateBasket: false})
    }, 300)
  }
  
  return {
    animateBasket
  };
}
