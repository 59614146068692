import React from 'react';
import { IonContent, IonIcon, IonLabel, IonList, IonMenu, IonMenuToggle } from '@ionic/react';

import MenuLogin from './MenuLogin';
import MenuItems from './MenuItems';
import MenuItemsGalleries from './MenuItemsGalleries';
import { MenuItem } from '../../styled/menu.styled';
import { homeOutline, homeSharp } from 'ionicons/icons';
import { useLocation } from 'react-router';

const Menu: React.FC = () => {
  const location = useLocation()

  return (
    <IonMenu
      contentId="main"
      type="overlay"
    >
      <IonContent>
        {/* <MenuLogin /> */}
        <IonList>
          {/* <IonMenuToggle autoHide={false}>
            <MenuItem selected={location.pathname === '/produtos' ? true : false} routerLink={'/'} routerDirection="none" lines="none" detail={false}>
              <IonIcon
                slot="start"
                color={location.pathname === '/produtos' ? 'primary' : 'secondary'}
                ios={homeOutline}
                md={homeSharp}
              />
              <IonLabel>Home</IonLabel>
            </MenuItem>
          </IonMenuToggle> */}
          <MenuItemsGalleries />
          <MenuItems />
        </IonList>
      </IonContent>
    </IonMenu>
  );
};

export default Menu
