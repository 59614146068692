import { FacebookLogin, FacebookLoginResponse } from "@capacitor-community/facebook-login";
import { useCookies } from "react-cookie";
import { useRecoilState, useResetRecoilState } from "recoil";
import { useApi } from "./useApi";
import { userPerfilAtom } from "../recoil/atoms/userPerfil";

export function useLogin({callBack}: {callBack?: () => void}) {
  const [user, setUser] = useRecoilState(userPerfilAtom);
  const resetUser = useResetRecoilState(userPerfilAtom);
  const { getFacebookUser, loginSenha } = useApi();
  const [, setCookie, removeCookie] = useCookies(['token-face', 'token-google', 'token']);

  const signIn = ({email, senha}: {email: string, senha: string}) => {
    return loginSenha({email: email, senha: senha})
      .then(res => {
        setCookie('token', res.token)
        setUser({
          id: res.cliente.id,
          nome: res.cliente.nome,
          email: res.cliente.email,
          cpf: res.cliente.cpf,
          telefone: res.cliente.telefone,
          address: res.cliente.entrega || [],
          logged: true,
        })
        if (callBack) {
          callBack()
        }
      })
  }

  const createPassword = () => {
    console.log('create password');
    return true
  }

  const signInFacebook = async () => {
    // Cada vez que o login é efetivado o Token do facebook é atualizado, nesses casos ver o que fazer...
    const FACEBOOK_PERMISSIONS = ['email', 'user_birthday', 'user_photos', 'user_gender'];

    const resFaceLogin: FacebookLoginResponse = await FacebookLogin.login({ permissions: FACEBOOK_PERMISSIONS })
    
    if (resFaceLogin.accessToken) {
      const userFacebook = await getFacebookUser(resFaceLogin.accessToken.token)
      setCookie('token-face', resFaceLogin.accessToken.token)
      
      setUser({
        ...user,
        ...userFacebook,
        faceToken: resFaceLogin.accessToken?.token || '',
      })
      if (callBack) {
        callBack()
      }
    }
  }

  const signInGoogle = () => {
    console.log('test')  
  }

  const logout = async () => {
    resetUser()
    removeCookie('token')
    removeCookie('token-face')
    removeCookie('token-google')
    if (callBack) {
      callBack()
    }
  }

  return {
    signIn,
    createPassword,
    signInFacebook,
    signInGoogle,
    logout,
  }
}