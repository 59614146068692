import React, { useEffect, useState } from 'react';
import {
  IonContent,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonPage,
} from '@ionic/react';
import HeroText from '../components/HeroText';
import Header from '../components/Header/Header';
import { Gallery } from '../components/Gallery';
import Footer from '../components/Footer';
import { CenterLoading, VerticalSpaceMedium, WrapperGray } from '../styled/common.styled';
import { useRecoilState } from 'recoil';
import { galeriesState } from '../recoil/atoms/galeries';
import { usePageName } from 'react-page-name';
import { GalleryType } from '../types/Gallery.type';
import { Banners } from '../components/Banners';
import ConteudoLoja from '../components/ConteudoLoja';

const Produtos: React.FC = () => {
  usePageName('Catálogo de Produtos Pão na Porta')
  const [quantGallery, setQuantGallery] = useState(2)
  const [galleries] = useRecoilState(galeriesState);
  const [lazyLoadGalleries, setLazyLoadGalleries] = useState<GalleryType[]>([])

  useEffect(() => {
    setLazyLoadGalleries(galleries.length > 0 ? galleries.slice(0, quantGallery) : [])
  }, [galleries])

  return (
    <IonPage id="comprar">
      <Header />
      <IonContent>
        <Banners />
        <ConteudoLoja />
        {/* <WrapperGray className='ion-padding'>
          <HeroText />
          {lazyLoadGalleries.length === 0 && <CenterLoading />}
          {lazyLoadGalleries.map((gallery) => {
            return gallery.produtos.length > 0 && <Gallery
              key={gallery.id}
              title={gallery.nome}
              slug={gallery.slug}
              products={gallery.produtos.slice(0, 15)}
            />
          })}
          <IonInfiniteScroll
            onIonInfinite={(ev) => {
              if(galleries.length > 0) {
                setQuantGallery(quantGallery + 2)
                setLazyLoadGalleries(galleries.length > 0 ? galleries.slice(0, quantGallery + 2) : [])
              }
              ev.target.complete()
            }}
          >
            <IonInfiniteScrollContent></IonInfiniteScrollContent>
          </IonInfiniteScroll>
          <VerticalSpaceMedium />
        </WrapperGray> */}
        <Footer />
      </IonContent>
    </IonPage>
  );
};

export default Produtos;
