import React from 'react';
import { IonButton, IonIcon, useIonToast } from "@ionic/react"
import { addOutline, addSharp } from "ionicons/icons"
import { useCart } from "react-use-cart"
import { BuyButtonWeight, CountItems, WrapperBuyMore } from "../../styled/product-card.styled"
import { ProductType } from "../../types/Product.type"
import { useAnimate } from '../../hooks/useAnimate';
import RemoveItem from './RemoveItem';

interface BuyButtonProps {
  product: ProductType
  inline?: boolean
}

const BuyNoItems = ({product}) => {
  const { addItem } = useCart();
  const { animateBasket } = useAnimate();

  return (
    <div>
      <BuyButtonWeight
        onClick={() => {
          addItem(product)
          animateBasket()
        }}
        expand="full"
        mode='md'
      >
        Quero
      </BuyButtonWeight>
    </div>
  )
}

const BuyHasItems = ({inline, product}) => {
  const { animateBasket } = useAnimate();
  const { getItem, updateItemQuantity } = useCart();
  const [present, dismiss] = useIonToast();

  const productQuant = getItem(product.id).quantity;

  return (
    <WrapperBuyMore inline={inline}>
      <RemoveItem id={product.id} />
      <CountItems>
        { getItem(product.id).quantity }
      </CountItems>
      <IonButton size="small" shape="round" mode='md' onClick={() => {
          if (productQuant >= product.disponivel) {
            present({
              buttons: [{ text: 'fechar', handler: () => dismiss() }],
              message: 'Quantidade acima do estoque disponivel',
              duration: 5000,
            })
          } else {
            updateItemQuantity(product.id, productQuant +1)
            animateBasket()
          }
        }}>
        <IonIcon ios={addOutline} md={addSharp} />
      </IonButton>
    </WrapperBuyMore>
  )
}

export const BuyButton: React.FC<BuyButtonProps> = ({
  product,
  inline = true
}) => {
  const { inCart } = useCart();

  if (inCart(product.id)) {
    return <BuyHasItems inline={inline} product={product} />
  }

  return <BuyNoItems product={product} />
}
