import { ProductType } from "../types/Product.type"
import { ImageSize } from "../types/Product.type"

export function mainImage(product: ProductType) {
  const mockImage = `${process.env.PUBLIC_URL}/assets/images/logo-acesso-black.png`

  if(product.imagens && product.imagens.length > 0) {
    return product.imagens[0].find(img => img.tipo === ImageSize.LARGE)?.url
  }
  return mockImage
}

export function thumbImage(product: ProductType) {
  const mockImage = `${process.env.PUBLIC_URL}/assets/images/logo-acesso-black.png`

  if(product.imagens && product.imagens.length > 0) {
    return product.imagens[0].find(img => img.tipo === ImageSize.THUMB)?.url
  }
  
  return mockImage
}

export function mediumImage(product: ProductType) {
  const mockImage = `${process.env.PUBLIC_URL}/assets/images/logo-acesso-black.png`

  if(product.imagens && product.imagens.length > 0) {
    return product.imagens[0].find(img => img.tipo === ImageSize.MEDIUM)?.url
  }
  
  return mockImage
}

export function isPromo(product: ProductType) {
  return false
}

export function lastUnits(product: ProductType) {
  return product.disponivel < 9
}
