import React, { memo } from 'react';
import { IonButtons, IonMenuButton} from '@ionic/react';

const HeaderMenuButton: React.FC = () => {
  return (
    <IonButtons>
      <IonMenuButton />
    </IonButtons>
  )
}

export default memo(HeaderMenuButton)
