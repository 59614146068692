import { IonBreadcrumb, IonSpinner } from "@ionic/react";
import styled from "styled-components";

interface PointerBreadcrumbProps {
  pointer?: Boolean;
}


export const MinContentSize = styled.div`
  min-height: 100vh;
`

export const PointerBreadcrumb = styled(IonBreadcrumb)<PointerBreadcrumbProps>`
  cursor: ${props => props.pointer ? 'pointer' : 'default'};
`

export const WrapperGray = styled.div`
  background-color: #F4F4F4;
`

export const VerticalSpaceMedium = styled.div`
  height: 40px;
  width: 100%;
`

export const CenterLoading = styled(IonSpinner)`
  width: 100%;
`
