import { IonIcon } from "@ionic/react";
import styled from "styled-components";

interface ImgFullWrapperProps {
  image: string
}

export const LojaWrapper = styled.div`
  width: 100%;
  padding: 16px;
  background-color: var(--ion-color-tertiary);
  color: var(--ion-color-light);
  text-align: center;
`

export const LojaConteudo = styled.div<{invertContent?: boolean}>`
  margin-top: -4px;
  display: flex;
  ${props => props.invertContent ? 'flex-wrap: wrap-reverse;' : 'flex-wrap: wrap'}
`

export const ImgFullWrapper = styled.div<ImgFullWrapperProps>`
  width: 50%;
  height: 500px;
  background-image: url(${props => props.image});
  background-size: cover;
  background-position: center;

  @media(max-width: 768px) {
    width: 100%;
    height: 300px;
  }
`

export const CatalogoLoja = styled.div`
  padding: 16px 32px;
  color: var(--ion-color-dark);
  width: 50%;
  height: 550px;

  @media(max-width: 768px) {
    width: 100%;
    height: 550px;
  }
`

export const LocationIcon = styled(IonIcon)`
  margin-right: 8px;
`
