import styled from "styled-components";

interface FooterWrapperProps {
  home?: boolean
}

export const FooterWrapper = styled.div<FooterWrapperProps>` 
  position: relative;
  bottom: 0;
  padding: 24px;
  text-align: center;
  background-color: var(--ion-color-tertiary);
  color: var(--ion-color-light);
`
