import React from 'react';
import {IonContent, IonPage,} from '@ionic/react';
import Header from '../components/Header/Header';
import Footer from '../components/Footer';
import {TermosWrapper} from '../styled/termos.styled';
import { usePageName } from 'react-page-name';

const Privacidade: React.FC = () => {

  usePageName('Cookies');

  return (
    <IonPage id="cookies">
      <Header/>
      <IonContent>
        <TermosWrapper>
          <h2>Política de Cookies</h2>

          <p>Olá! Seja bem-vindo à Política de Cookies do Pão na Porta (“nós”). O
            objetivo desta política fornecer ao usuário do Site e dos serviços oferecidos pelo Pão na Porta (“usuário”
            ou “você”), informações claras e acessíveis sobre os cookies que utilizamos em nosso website:
            www.queropaonaporta.com.br e nos aplicativos para dispositivos móveis (coletivamente, “Sites”) &nbsp;e a
            forma
            como isso afeta sua experiência como usuário.<br/>
            <br/>
            A presente Política de Cookies é
            complementar à nossa Política de Privacidade, que você pode acessar clicando
            <a href="https://queropaonaporta.com.br/politicas-de-privacidade">aqui</a>.

          </p>

          <br/>
          <h3>1. O que são cookies?</h3>
          <p>
            Cookies são ferramentas essenciais na navegação online.
            Consistem em arquivos que identificam a interação entre você, nossos Sites e nossas redes
            sociais ou anúncios, e que são transferidos para o seu equipamento (computador, tablet ou
            smartphone) visando otimizar e personalizar a sua experiência de navegação nos Sites. O termo
            “cookie” pode ser usado para descrever uma série de tecnologias, incluindo, mas não se limitando
            a pixel tags, web beacons e identificadores de dispositivo móvel.<br/>
            <br/>
            A Pão na Porta usa
            tanto cookies de sessão quanto cookies persistentes. Os cookies de sessão coletam informações
            que não permitem a identificação do usuário e são automaticamente deletados quando a janela do
            seu navegador for fechada. Já os cookies persistentes coletam informações que possibilitam a
            identificação do usuário e &nbsp;são armazenados no seu computador ou dispositivo móvel até sua
            expiração.&nbsp;
          </p>

          <p>Além disso, os cookies utilizados nos Sites podem ser
            “proprietários”, isto é, cookies definidos por nós - ou por um terceiro em nosso nome - ou “de
            terceiros”, que &nbsp;permitem que recursos ou funcionalidades de terceiros confiáveis sejam
            fornecidos nos Sites ou por meio deles ou em serviços que você utiliza como anúncios, conteúdo
            interativo ou analytics. Esses terceiros podem reconhecer o seu dispositivo quando você navega
            em nossos Sites ou utiliza nossos serviços ou quando você navega em outros sites ou utiliza
            outros serviços.&nbsp;
          </p>

          <br />
          <h3>2. Como os cookies são utilizados pela Pão na Porta</h3>
          <p>
            Abaixo listamos os diferentes tipos de
            cookies que podem ser utilizados nos Sites da Pão na Porta.
          </p>

          <strong>Cookies Essenciais</strong>

          <p>Os cookies essenciais são necessários para o funcionamento
            dos Sites e prestação dos serviços a você. Sem estes cookies, os Sites não funcionam tão bem
            como nós gostaríamos e podemos não ser capazes de fornecer determinados serviços ou recursos a
            eles associados.
          </p>

          <strong>Cookies de Preferências</strong>

          <p>Os cookies de preferências coletam informações sobre suas
            escolhas e preferências, permitindo que os nossos Sites se lembrem do idioma, localização,
            dispositivo utilizado ou outras configurações, para que sua experiência seja personalizada de
            acordo com essas preferências.
          </p>

          <strong>Cookies Analíticos</strong>

          <p>Os cookies analíticos coletam informações sobre a utilização
            dos Sites, permitindo-nos aperfeiçoar o seu funcionamento. Mostram, por exemplo, quais são as
            páginas mais visitadas nos Sites, ajudam-nos a registar quaisquer dificuldades que os usuários
            sintam na navegação nos Sites, e mostram-nos se a nossa publicidade é eficaz ou não. Isso nos
            possibilita ver os padrões de uso dos Sites de forma anônima e agregada.
          </p>

          <br />
          <h3>3. Plugins de mídia social</h3>

          <p>Também usamos botões e/ou plugins de mídia social nos Sites.
            Eles permitem que você se conecte com sua rede social de várias maneiras. Para que estes
            funcionem, os sites de mídia social, incluindo Facebook®, Twitter® e Linkedin®, definirão
            cookies em nossos Sites que podem ser usados para aprimorar seu perfil em seus sites ou contribuir
            com os dados armazenados para vários fins delineados em sua respectiva política de
            privacidade.
          </p>

          <br />
          <h3>4. Como Gerir Cookies?</h3>

          <p>Embora a maioria dos navegadores de Internet esteja
            inicialmente configurada para aceitar cookies automaticamente, você pode alterar as
            configurações para bloquear esses cookies ou para alertá-lo quando os cookies são enviados para
            o dispositivo. Para saber melhor consulte as opções de ajuda de seu navegador. Abaixo você
            encontrará alguns links para os navegadores mais conhecidos:
          </p>

          <p><a style={{fontWeight: 'bold', textDecoration: 'none'}}
            href="https://support.microsoft.com/pt-br/topic/alterar-as-configura%C3%A7%C3%B5es-de-seguran%C3%A7a-e-privacidade-no-internet-explorer-11-9528b011-664c-b771-d757-43a2b78b2afe#ie=ie-11"
          >Internet Explorer</a><br/>
            <a style={{fontWeight: 'bold', textDecoration: 'none'}}
              href="https://support.mozilla.org/pt-BR/kb/cookies-informacoes-sites-armazenam-no-computador?redirectlocale=pt-BR&amp;redirectslug=cookies-informacoes-armazenadas-por-sites-em-seu-c"
            >Firefox</a><br/>
            <a style={{fontWeight: 'bold', textDecoration: 'none'}}
              href="https://support.google.com/chrome/answer/95647?ref_topic=3434352&amp;hl=pt-BR"
            >Chrome</a><br/>
            <a style={{fontWeight: 'bold', textDecoration: 'none'}}
              href="https://support.apple.com/pt-br/HT201265">Safari
              Web e iOS</a>
          </p>

          <p>Note que se os cookies forem desativados ou removidos, nem
            todos os recursos dos Sites poderão funcionar como pretendido. Por exemplo, você pode não ser
            capaz de visitar certas áreas do nosso Site ou pode não receber informações personalizadas
            quando visitar o Site.
          </p>

          <p>Caso você use diferentes dispositivos para visualizar e
            acessar os Sites (por exemplo, computador, smartphone, tablet, etc.), deve configurar cada
            navegador de cada dispositivo para atender às suas preferências de cookies.
          </p>

          <br />
          <h3>5. Contate-nos</h3>

          <p>Caso tenha alguma pergunta acerca desta Política de Cookies,
            por favor contate-nos pelo e-mail <em>atendimento@queropaonaporta.com.br</em>.
          </p>

        </TermosWrapper>
        <Footer/>
      </IonContent>
    </IonPage>
  );
};

export default Privacidade;
