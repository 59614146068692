import { useEffect } from "react";
import { useRecoilState } from "recoil";
import { useApi } from "../hooks/useApi";
import { userPerfilAtom } from "../recoil/atoms/userPerfil";

const LoginProvider = ({children}) => {
  const [, setUser] = useRecoilState(userPerfilAtom);
  const { authMe } = useApi()

  useEffect(() => {
    authMe()
      .then(user => {
        setUser({
          id: user.id,
          nome: user.nome,
          email: user.email,
          cpf: user.cpf,
          telefone: user.telefone,
          logged: true,
          address: user.entrega || [],
          vendedor: user.vendedor
        })  
      })
  }, [])
  
  return children
}

export default LoginProvider
