import { MetaInfo } from "./MetaInfo.type"

type Categoria = {
  id: number,
  nome: string,
  slug: string,
}

export enum ImageSize {
  LARGE = 'LARGE',
  MEDIUM = 'MEDIUM',
  THUMB = 'THUMB'
}

export type ImageType = {
  tipo: ImageSize,
  url: string  
}

export type ProductType = {
  id: string,
  produtoId?: string,
  ativo: boolean,
  carta: boolean,
  postavel: boolean,
  codigo: string,
  modico: boolean,
  disponivel: number,
  preco: string,
  nome: string,
  slug: string,
  unidade: string,
  plataformas: Array<string>
  precoQuantidade: Array<number>,
  imagens?: Array<Array<ImageType>>,
  descricao?: string,
  price?: string,
  oferta?: string,
  categoriaId: number,
  categoria: Categoria,
  quantidade?: number,
  meta?: MetaInfo
}
