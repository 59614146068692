import React from "react"
import { useHistory } from "react-router";
import Slider from "react-slick";

export const Banners: React.FunctionComponent = () => {
  const history = useHistory()

  const settings = {
    infinite: true,
    speed: 600,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    autoplay: true,
    autoplaySpeed: 5000,
  };

  return (
    <Slider {...settings}>
    {/* <div>
      <img width='100%' alt='banner' src={`${process.env.PUBLIC_URL}/assets/images/banner-fechados.jpg`} />
    </div> */}

      <div>
        <img width='100%' alt='banner' src={`${process.env.PUBLIC_URL}/assets/images/banner-retorno.jpg`} />
      </div>
      
      {/* <div
        style={{cursor: 'pointer'}}
        onClick={() => history.push('/produto/panetone-de-figo-com-laranja')}
      >
        <img width='100%' alt='banner' src={`${process.env.PUBLIC_URL}/assets/images/destaque-panetone.jpg`} />
      </div> */}
    </Slider>
  )
}
