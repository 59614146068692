import React from 'react';
import { Title, SubTitle, HeroWrapper, HeroTextWrapper } from "../styled/hero-text.styled";

interface HeroTextProps {
  backgroundImage?: string
  textColor?: string
  height?: number
  background?: boolean
}

const HeroText: React.FC<HeroTextProps> = ({backgroundImage, textColor, height, background}) => {
  return (
    <HeroWrapper height={height} backgroundImage={backgroundImage}>
      <HeroTextWrapper background={background} className="ion-padding">
        <Title textColor={textColor}>Produtos feitos com carinho na Comunidade.</Title>
        <SubTitle textColor={textColor}>Pães de fermentação natural, doces, frutas e verduras e muito mais!</SubTitle>
        <SubTitle textColor={textColor}>Peça até quarta 12h para receber seu pedido ainda na mesma semana</SubTitle>
      </HeroTextWrapper>
    </HeroWrapper>
  );
};

export default HeroText;
