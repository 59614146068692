import React from 'react';
import { IonIcon, IonLabel, useIonModal } from "@ionic/react";
import { personCircleOutline, personCircleSharp } from "ionicons/icons";
import { useLocation } from "react-router";
import { AvatarLoginDesk, MenuItemDesk } from "../../styled/menu.styled";
import appPages from "./MenuLinks";
import { userPerfilAtom } from '../../recoil/atoms/userPerfil';
import { useRecoilState } from 'recoil';
import LoginModal from '../LoginModal';
import MenuItemsGalleriesDesk from './MenuItemsGalleriesDesk';

const MenuItemsDesk: React.FC = () => {
  const location = useLocation();
  const [user] = useRecoilState(userPerfilAtom);
  
  const [preset, dismiss] = useIonModal(LoginModal, {
    onDismiss: () => dismiss()
  });

  const toggleDark = () => {
    const body = document.querySelector('body')
    body?.classList.toggle('dark')
  }

  return (
    <>
      <MenuItemDesk
        selected={location.pathname === '/produtos' ? true : false}
        routerLink={'/'}
        routerDirection="none"
        lines="none"
        detail={false}
        color='primary'
      >
        <IonLabel>Home</IonLabel>
      </MenuItemDesk>
      
      {/* <MenuItemsGalleriesDesk /> */}

      {appPages.map((appPage, index) => {
        return (
          <MenuItemDesk
            selected={location.pathname === appPage.url ? true : false}
            routerLink={appPage.url}
            routerDirection="none"
            lines="none"
            detail={false}
            color='primary'
            key={index}
          >
              <IonLabel>{appPage.title}</IonLabel>
          </MenuItemDesk>
        );
      })}
      { !user.logged && (
        <MenuItemDesk lines='none' color='primary' onClick={() => preset()}>
          <AvatarLoginDesk>
            <IonIcon size='large' ios={personCircleOutline} md={personCircleSharp} />
          </AvatarLoginDesk>
          <IonLabel>
              <h2>Faça seu login</h2>
              <p>ou cadastre-se</p>
            </IonLabel>
        </MenuItemDesk>
      )}
      { user.logged && (
        <MenuItemDesk lines='none' color='primary' onClick={() => preset()}>
          <AvatarLoginDesk>
            <IonIcon size='large' ios={personCircleOutline} md={personCircleSharp} />
          </AvatarLoginDesk>
          <IonLabel>
              <h2>{user.nome}</h2>
              <p>{user.email}</p>
            </IonLabel>
        </MenuItemDesk>
      )}
      {/* <MenuItemDesk onClick={() => toggleDark()} lines="none" color='primary'>
        <IonIcon
          slot="start"
          color='dark'
          ios={colorPaletteOutline}
          md={colorPaletteSharp}
        />
        <IonLabel>Mudar Tema</IonLabel>
      </MenuItemDesk> */}
    </>
  )
}

export default MenuItemsDesk
