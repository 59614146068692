import React from 'react';
import { IonButton } from "@ionic/react";
import { bagOutline, bagSharp, locationOutline, locationSharp } from "ionicons/icons";
import {
  CatalogoLoja, ImgFullWrapper, LocationIcon, LojaConteudo, LojaWrapper
} from "../styled/conheca-nossa-loja";
import ConteudoLoja from './ConteudoLoja';

const ConhecaNossaLoja: React.FC = () => {
  const loja1 = `${process.env.PUBLIC_URL}/assets/images/leela-loja.jpg`

  return (
    <>
      <div>
        <img width='100%' alt='banner' src={`${process.env.PUBLIC_URL}/assets/images/banner-retorno.jpg`} />
      </div>
      
      <ConteudoLoja />

      {/* <LojaConteudo>
        <CatalogoLoja>
          <h2>VEM BATER NA NOSSA PORTA!</h2>
          <h2>
            Um respiro em meio ao caos da Cidade Baixa! Com o fim do lockdown nós abrimos nossa loja na cidade.
            É um lugar único, mágico, onde você pode sentir todo o carinho e energia da comunidade e todo amor
            que colocamos em cada detalhe.
          </h2>
          <p>
            Uma pipa de aproximadamente 4 metros de altura encanta logo na entrada.
            E é uma delícia de lugar para se tomar um café especial, do Café do Mercado.
          </p>

          <p>
            Rua José do Patrocínio, 366 - Cidade Baixa
          </p>
          <p>
            Segunda das <strong>11:00 até 21:30</strong> <br />
            Terça à Sexta das <strong>9:30 até as 21:30</strong> <br />
            Sábado à Domingo das <strong>8:30 até as 21:30</strong> <br />
          </p>
          <a href="https://goo.gl/maps/QEs7p8yy4uxjhyzLA" target='_blank' rel="noreferrer">
            <IonButton>
              <LocationIcon size="medium" md={locationSharp} ios={locationOutline} />
              <span>Como Chegar</span>
            </IonButton>
          </a>
          <h2>Faça sua encomenda na loja</h2>
          <p>
            Você também pode encomendar nossos produtos da loja <br />
          </p>
          <a href="https://pao-na-porta.goomer.app/" target='_blank' rel="noreferrer">
            <IonButton>
              <LocationIcon size="medium" md={bagSharp} ios={bagOutline} />
              <span>Ver catálogo</span>
            </IonButton>
          </a>
        </CatalogoLoja>
        <ImgFullWrapper />
      </LojaConteudo> */}

    </>
  );
};

export default ConhecaNossaLoja;
