import React, { useState } from "react";
import { useRecoilState, useResetRecoilState } from "recoil";
import { IonButton, IonCard, IonCheckbox, IonIcon, IonItem, IonLabel, IonList, IonText, useIonModal, useIonToast } from "@ionic/react"
import { closeOutline, closeSharp, logInOutline, logInSharp, personAddOutline, personAddSharp } from "ionicons/icons"
import { ModalContent, ModalHeader, ModalHeaderTitle, ModalWrapper } from "../styled/products-filter.styled"
import { LoginBox } from "../styled/login.styled";
import LoginModal from "./LoginModal";
import UserDadosForm from "./UserDadosForm";
import UserPasswordForm from "./UserPasswordForm";
import { useInputValidation } from "../hooks/useInputValidation";
import { userRegisterAtom } from "../recoil/atoms/userRegister";
import { useApi } from "../hooks/useApi";
import { useLogin } from "../hooks/useLogin";

const SignupModal: React.FC<{onDismiss: () => void}> = ({onDismiss}) => {
  const [user] = useRecoilState(userRegisterAtom)
  const resetUser = useResetRecoilState(userRegisterAtom)
  const [present, dismissToaster] = useIonToast();
  const { registerUser } = useApi()
  const { signIn } = useLogin({});
  const [termos, setTermos] = useState(true);
  
  const [preset, dismiss] = useIonModal(LoginModal, {
    onDismiss: () => dismiss()
  });

  const { isValidUserPasswordWithMessage, isValidUserDataWithMessage } = useInputValidation();

  const subscribe = () => {
    if (
      isValidUserDataWithMessage(user) &&
      isValidUserPasswordWithMessage(user, termos)
    ) {
      registerUser(user)
        .then(res => {
          onDismiss()
          resetUser()
          present({
            buttons: [{ text: 'fechar', handler: () => dismissToaster() }],
            message: 'Cadastro feito com sucesso',
            duration: 5000,
          })
        })
        .then(res => {
          signIn({senha: user.password, email: user.email})
        })
        .catch(e => {
          present({
            buttons: [{ text: 'fechar', handler: () => dismissToaster() }],
            message: e.response.data.message,
            duration: 5000,
          })
        })
    }
  }

  return (
    <ModalWrapper>
      <ModalHeader>
        <ModalHeaderTitle>
          Cadastro
        </ModalHeaderTitle>
        <IonButton size="small" shape="round" onClick={() => onDismiss()}>
          <IonIcon slot="icon-only" md={closeSharp} ios={closeOutline} />
        </IonButton>
      </ModalHeader>

      <ModalContent>
        <IonText color="dark">
          <IonCard>
            <UserDadosForm />
            <UserPasswordForm />
            <IonList>
              <IonItem>
                <IonLabel className="ion-text-center">
                  Ler <a href='/termos-de-uso' target='_blank' rel='noreferrer'>Termos e condições de uso</a>
                </IonLabel>
              </IonItem>
              <IonItem lines="none">
                <IonLabel>
                  Li e aceito os Termos de Uso e Política de Privacidade
                </IonLabel>
                <IonCheckbox
                  checked={termos}
                  slot="start"
                  onIonChange={e => setTermos(e.detail.checked)}
                />
              </IonItem>
            </IonList>
          </IonCard>
          <LoginBox>
            <IonButton
              onClick={() => subscribe()}
              expand="full"
              color="secondary"
              className="ion-margin-top"
            >
              <IonIcon slot="start" ios={personAddOutline} md={personAddSharp} />
              Criar conta
            </IonButton>
            <IonButton
              onClick={() => {
                onDismiss()
                preset()
              }}
              expand='full'
              color="primary"
              className="ion-margin-top"
            >
              <IonIcon slot="start" ios={logInOutline} md={logInSharp} />
              Já tenho uma conta
            </IonButton>
          </LoginBox>
        </IonText>
      </ModalContent>
    </ModalWrapper>
  )
}

export default SignupModal
