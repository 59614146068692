import React from "react";
import { IonButton, IonCard, IonIcon, IonItem, IonList } from "@ionic/react"
import { formatPhoneNumberIntl } from 'react-phone-number-input'
import { logOutOutline, logOutSharp } from "ionicons/icons"
import { LoginBox } from "../styled/login.styled";
import { useLogin } from "../hooks/useLogin";
import { useRecoilState } from "recoil";
import { userPerfilAtom } from "../recoil/atoms/userPerfil";
import { format as formatCpf } from "@fnando/cpf";
import UserAddress from "./UserAddress";
import { VerticalSpace } from "../styled/address.styled";

const LoginUserData: React.FC <{onDismiss: () => void}> = ({onDismiss}) => {
  const [user] = useRecoilState(userPerfilAtom)
  const { logout } = useLogin({callBack: onDismiss})
  
  return (
    <>
      <LoginBox>
        <IonCard>
          <h3>Meus dados de cadastro</h3>
          <IonList>
            <IonItem>
              {user.nome}
            </IonItem>
            <IonItem>
              {user.email}
            </IonItem>
            <IonItem>
              {formatPhoneNumberIntl(user.telefone || '')}
            </IonItem>
            <IonItem lines="none">
              {user.cpf ? formatCpf(String(user.cpf)) : ''}
            </IonItem>
          </IonList>
        </IonCard>
      </LoginBox>
      <VerticalSpace />
      <UserAddress />
      <LoginBox>
        <IonButton
          onClick={() => logout()}
          expand="full"
          color="primary"
          className="ion-margin-top"
        >
          <IonIcon slot="start" ios={logOutOutline} md={logOutSharp} />
          Sair
        </IonButton>
      </LoginBox>
    </>
  )
}

export default LoginUserData
