import { IonRouterOutlet } from '@ionic/react';
import { Route, useHistory, useLocation } from 'react-router-dom';
import { useRouteMatch } from "react-router-dom";
import Menu from './components/Menu/Menu';
import Produtos from './pages/Produtos';
import Cookies from './pages/Cookies';
import Galeria from './pages/Galeria';
import QuemSomos from './pages/QuemSomos';
import Checkout from './pages/Checkout';
import Termos from './pages/Termos';
import Privacidade from './pages/Privacidade';
import Produto from './pages/Produto';
import MeusPedidos from './pages/MeusPedidos';
import Loja from './pages/Loja';
import AlterarSenha from './pages/AlterarSenha';
import { useApi } from './hooks/useApi';
import { galeriesState } from './recoil/atoms/galeries';
import { useRecoilState } from 'recoil';
import { useCookies } from 'react-cookie';
import { useEffect, useState } from 'react';

const PageRouter: React.FC = () => {
  const version = process.env.REACT_APP_VERSION
  const fiveMin = 300000
  const history = useHistory()
  const [galleries, setGalleries] = useRecoilState(galeriesState);
  const [isLoadingGalleries, setIsLoadingGalleries] = useState(false)
  const [cookies, setCookie] = useCookies(['lastSearch', 'lastUpdate', 'vendedor']);
  const { getAllGalleries } = useApi();
  const { search } = useLocation();
  
  let inProdutos = useRouteMatch("/produtos");
  
  const shouldGetGalleries = () => {
    const needReload = !cookies.lastSearch || (Date.now() - cookies.lastSearch > fiveMin)
    if(needReload) {
      setCookie('lastSearch', Date.now(), { path: '/' })
    }
    return needReload
  }

  const shouldUpdateFront = (versionApi: string | number) => {
    if(versionApi != version) {
      const needUpdate = !cookies.lastUpdate || (Date.now() - cookies.lastUpdate > fiveMin)
      if (!cookies.lastUpdate || needUpdate) {
        setCookie('lastUpdate', Date.now(), { path: '/' })
        history.go(0)
      }
    }
  }

  useEffect(() => {
    if((galleries.length === 0 && !isLoadingGalleries) || (inProdutos && shouldGetGalleries())) {
      setIsLoadingGalleries(true)
      LoadAllProducts()
      const intervalId = setInterval(LoadAllProducts, 20000)
      return () => {
        clearInterval(intervalId);
      };
    }
  }, [])

  const LoadAllProducts = () => {
    getAllGalleries()
      .then(res => {
        setCookie('lastSearch', Date.now(), { path: '/' })
        setGalleries(res.galleriesList)
        shouldUpdateFront(res.version)
        setIsLoadingGalleries(false)
      })
      .catch(() => {
        setIsLoadingGalleries(false)
      })
  }

  useEffect(() => {
    const s = new URLSearchParams(search)
    const searchString = s.get('vendedor') || ''
    if (searchString) {
      setCookie('vendedor', searchString, { path: '/' })
    }
  }, [search])

  return (
    <>
      <Menu />
      <IonRouterOutlet id="main">
        <Route path="/" exact={true}>
          <Produtos />
          {/* <Redirect to="/produtos" /> */}
        </Route>
        <Route path="/quem-somos" exact={true}>
          <QuemSomos />
        </Route>
        <Route path="/loja" exact={true}>
          <Loja />
        </Route>
        <Route path={['/produtos', '/produtos/:login']} exact={true}>
          <Produtos />
        </Route>
        {/* <Route path="/finalizar-compra" exact={true}>
          <Checkout />
        </Route> */}
        <Route path="/termos-de-uso" exact={true}>
          <Termos />
        </Route>
        <Route path="/politicas-de-privacidade" exact={true}>
          <Privacidade />
        </Route>
        <Route path="/politicas-de-cookies" exact={true}>
          <Cookies />
        </Route>
        {/* <Route path={['/produto/:id']}>
          <Produto />
        </Route> */}
        {/* <Route path={['/catalogo/:slug/:search', '/catalogo/:slug']}>
          <Galeria />
        </Route> */}
        {/* <Route path={['/meus-pedidos']}>
          <MeusPedidos />
        </Route> */}
        {/* <Route path={['/recupera-senha/:token']}>
          <AlterarSenha />
        </Route>
        <Route path={['/alterar-senha/:token']}>
          <AlterarSenha />
        </Route> */}
        {/* <Route render={() => {
          window.location.replace('/produtos')
          return <div></div>
        }}/> */}
      </IonRouterOutlet>
    </>
  );
};

export default PageRouter;
