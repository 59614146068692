import { IonBadge, IonButton, IonImg, IonItem, IonTitle } from '@ionic/react';
import { Link } from "react-router-dom"
import styled, { css, keyframes } from 'styled-components';

const cartCount = keyframes `
  0% {
    top: 0px;
  }
  55% {
    top: 36px;
  }
  100% {
    top: 26px;
  }
`

interface BadgeCartIconProps {
  animate?: boolean
}

interface ClickableItemProps {
  visibility: boolean
}

export const LogoImg = styled(IonImg)`
  height: 30px;
`

export const HeaderWrapper = styled.div`
  position: relative;
  margin-top: 8px;
  display: flex;
  height: 100%;
  align-items: center;
`

export const BadgeCartIcon = styled(IonBadge)<BadgeCartIconProps>`
  position: absolute;
  top: 26px;
  margin-left: 12px;

  ${props => props.animate ? css`
    animation: ${cartCount} 0.3s linear 1;
  `: ``}
`

export const SearchWrapper = styled.div`
  margin-bottom: 8px;
  display: flex;
`

export const HomeLink = styled(Link)`
  color: white;
  text-decoration: none;

  img {
    max-height: 36px;
  }
`

export const HeaderLogoWrapper = styled(IonTitle)`
  text-align: center;
  margin-top: 4px;

  @media(min-width: 768px) {
    text-align: left;
  }
`

export const ClickableItem = styled(IonItem)<ClickableItemProps>`
  cursor: pointer;
  visibility: ${props => props.visibility ? 'visible' : 'hidden'};
  overflow: visible;
  position: relative;
`

export const GoSearchButton = styled(IonButton)`
  height: auto;
`
