import React, { useState } from 'react';
import { IonButton, IonCard, IonIcon, IonItem, IonLabel, IonListHeader, IonLoading, useIonModal, useIonToast } from "@ionic/react"
import { BadgeDeliveryDay, DeliveryDayText, FlexBox, ListAddress } from "../styled/address.styled"
import AddressCardHeader from './AddressCardHeader';
import { busOutline, busSharp, homeOutline, homeSharp } from 'ionicons/icons';
import { useRecoilState } from 'recoil';
import { userPerfilAtom } from '../recoil/atoms/userPerfil';
import { useApi } from '../hooks/useApi';
import AddressNewModal from './AddressNewModal';
import { AddressType } from '../types/UserApi.type';

const UserAddress: React.FC = () => {
  const [user, setUser] = useRecoilState(userPerfilAtom);
  const { authMe, deleteAddress, makeAddressDefault } = useApi()
  const [present, dismiss] = useIonToast();
  const [editingAddress, setEditingAddress] = useState<null | AddressType>(null);
  const [ isLoading, setIsLoading ] = useState(false)

  const handleDismiss = () => {
    dismissEdit();
  };

  const [presetEdit, dismissEdit] = useIonModal(AddressNewModal, {
    onDismiss: handleDismiss,
    editingAddress: editingAddress,
  });

  const editAddress = (address: AddressType) => {
    setEditingAddress(address)
    presetEdit()
  }
  
  const deleteAddressAction = (id) => {
    if(id) {
      setIsLoading(true)
      deleteAddress({addressId: id})
      .then(res => {
        setIsLoading(false)
        present({
          buttons: [{ text: 'fechar', handler: () => dismiss() }],
          message: res.message,
          duration: 5000,
        })  
      })
      .then(() => {
        authMe()
        .then(user => {
          setUser({
            ...user,
            logged: true,
            address: user.entrega || []
          })  
        })
      })
      .catch(e => {
        setIsLoading(false)
        present({
          buttons: [{ text: 'fechar', handler: () => dismiss() }],
          message: e.response.data.message,
          duration: 5000,
        })
      })
    } else {
      console.log('error')
    }
  }

  const setAddressDefault = (address: AddressType) => {
    setIsLoading(true)
    makeAddressDefault({address})
    .then(() => {
      authMe()
      .then(user => {
        setIsLoading(false)
        setUser({
          ...user,
          logged: true,
          address: user.entrega || []
        })  
      })
      .catch(() => setIsLoading(false))
    })
  }

  return (
    <IonCard>
      {isLoading &&
        <IonLoading
          isOpen={isLoading}
          onDidDismiss={() => setIsLoading(false)}
          message={'Carregando'}
          duration={5000}
        />
      }
      <AddressCardHeader
        title="ENDEREÇOS"
        iosIcon={homeOutline}
        mdIcon={homeSharp}
        addNew
      />
      {
        user.address.map((address, index) => {
          return (
            <ListAddress key={address.id}>
              <IonListHeader>
                <IonLabel><h1>{address.nome || 'Principal'}</h1></IonLabel>
                {address.default && 
                  <DeliveryDayText>
                    <BadgeDeliveryDay color="secondary">Principal</BadgeDeliveryDay>
                  </DeliveryDayText>
                }
                {!address.default && 
                  <DeliveryDayText>
                    <IonButton
                      color="tertiary"
                      fill='outline'
                      size='small'
                      onClick={() => setAddressDefault(address)}
                    >
                      <IonIcon slot="start" ios={busOutline} md={busSharp} />
                      Usar
                    </IonButton>
                  </DeliveryDayText>
                }
              </IonListHeader>
              <IonItem lines='none'>
                <IonLabel>
                  <h2>{address.cep}</h2>
                  <h3>{address.endereco}, {address.numero}</h3>
                  <p>{address.bairro}</p>
                  <p>{address.complemento}</p>
                  <p>{address.cidade}</p>
                  <p>{address.observacao}</p>
                </IonLabel>
              </IonItem>
              <IonItem lines={user.address.length -1 === index ? 'none' : 'full'}>
                <IonButton
                  slot='start'
                  color='secondary'
                  onClick={() => {
                    editAddress(address)
                  }}
                >
                  editar
                </IonButton>
                {!address.default && <IonButton
                  slot='end'
                  color='danger'
                  onClick={() => deleteAddressAction(address.id)}
                >
                  Remover
                </IonButton>}
              </IonItem>
            </ListAddress>
          )
        })
      }
    </IonCard>
  )
}

export default UserAddress
