import React from 'react';
import { IonIcon, IonLabel, IonMenuToggle } from "@ionic/react";
import { useLocation } from "react-router";
import { MenuItem } from "../../styled/menu.styled";
import appPages from "./MenuLinks";

const MenuItems: React.FC = () => {
  const location = useLocation()

  const toggleDark = () => {
    const body = document.querySelector('body')
    body?.classList.toggle('dark')
  }

  return (
    <>
      {appPages.map((appPage, index) => {
        return (
          <IonMenuToggle key={index} autoHide={false}>
            <MenuItem selected={location.pathname === appPage.url ? true : false} routerLink={appPage.url} routerDirection="none" lines="none" detail={false}>
              <IonIcon
                slot="start"
                color={location.pathname === appPage.url ? 'primary' : 'secondary'}
                ios={appPage.iosIcon}
                md={appPage.mdIcon}
              />
              <IonLabel>{appPage.title}</IonLabel>
            </MenuItem>
          </IonMenuToggle>
        );
      })}
      {/* <IonItem onClick={() => toggleDark()} lines="none">
        <IonIcon
          slot="start"
          color='tertiary'
          ios={colorPaletteOutline}
          md={colorPaletteSharp}
        />
        <IonLabel>Mudar Tema</IonLabel>
      </IonItem> */}
    </>
  )
}

export default MenuItems
