import React from 'react';
import { IonButton } from "@ionic/react";
import { logoWhatsapp } from "ionicons/icons"
import { WhatsappWrapper, WhatsContato, WhatsIcon } from "../styled/whatsapp-session.styled";

const WhatsAppSession: React.FC = () => {
  return (
    <WhatsappWrapper>
      <div>
        <h2>FAÇA SEU PEDIDO PELO WHATSAPP AGORA</h2>
        
        <p>
          Entregamos entre <strong>quinta e sexta feira</strong>, dependendo do local de entrega.
          </p>
          <p>
          Entregas em toda Porto Alegre, cidades vizinhas, litoral e serra gaúcha.
        </p>

        <p>
          
        </p>

        <h2>SOLICITE NOSSO CATALOGO E CONFIRA TODAS AS DELICIAS QUE PODEM CHEGAR NA SUA PORTA!</h2>
        <WhatsContato
          href="https://wa.me/5551982182121" target='_blank' rel="noreferrer"
        >
          <IonButton size="large">
            <WhatsIcon size="large" icon={logoWhatsapp}/>
            <span>51 98218-2121</span>
          </IonButton>
        </WhatsContato>
      </div>
    </WhatsappWrapper>
  );
};

export default WhatsAppSession;
