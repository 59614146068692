import { IonAccordion, IonAvatar, IonItem, IonList } from "@ionic/react";
import styled from "styled-components";

interface MenuItemProps {
  selected?: boolean
}

export const MenuLoginWrapper = styled.div`
  background-color: var(--ion-color-primary);
`

export const AvatarLogin = styled(IonAvatar)`
  display: flex;
  align-items: center;
  margin-right: 8px;
  cursor: pointer;
`

export const AvatarLoginDesk = styled(AvatarLogin)`
  height: 48px;
`

export const ListHeaderStyled = styled(IonList)`
  padding: 0;
  cursor: pointer;
`

export const MenuItem = styled(IonItem)<MenuItemProps>`
  color: ${props => props.selected ? 'var(--ion-color-primary)' : 'inherit'};
`

export const MenuItemDesk = styled(IonItem)<MenuItemProps>`
  max-width: 250px;
  cursor: pointer;
  color: ${props => props.selected ? 'var(--ion-color-secondary)' : 'inherit'};
`

export const LogoWrapper = styled.h2`
  padding: 0 24px 20px 24px;
  border-bottom: solid 1px #000;
`

export const IonAccordionCustom = styled(IonAccordion)`
  ion-icon {
    color: var(--ion-color-secondary) !important;
  }

  ion-menu-toggle ion-label {
    margin-left: 57px;
  }
`
