import { isPossiblePhoneNumber } from 'react-phone-number-input'
import { isValid as cpfValidator } from "@fnando/cpf";
import { AddressCorreios, UserRegisterType } from '../types/UserApi.type';
import { PedidoType } from '../types/Pedido.type';
import { useIonToast } from '@ionic/react';

export function useInputValidation() {
  const [present, dismissToaster] = useIonToast();

  const isValidNome = name => {
    const splitedName = name.trim().split(' ')

    if(splitedName.length < 2) {
      return false
    }

    if(splitedName[splitedName.length -1].length < 1) {
      return false
    }

    return true
  }

  const isValidEmail = email => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  }

  const isValidTelefone = telefone => {
    return isPossiblePhoneNumber(telefone)
  }

  const isValidCpf = cpf => {
    return cpfValidator(cpf)
  }

  const isValidSenha = password => {
    return password.length > 5
  }

  const isValidCep = cep => {
    cep = String(cep)
    if(isNaN(cep.replaceAll('-','')) || cep.replaceAll('-','').length !== 8) {
      return false
    }
    return true
  }

  const isValidCorreios = (address: AddressCorreios) => {
    return address.cidade !== '' && isValidCep(address.cep) && address.uf !== ''
  }

  const isValidPedido = (order: PedidoType) => {
    return order.enderecoId &&
      (order.entregaAt || order.entregaAt === '') &&
      order.freteId &&
      order.meioPagamentoId &&
      order.itens.length !== 0
  }
  
  const isValidAddress = (order: PedidoType) => {
    return !!order.enderecoId
  }

  const isValidShipping = (order: PedidoType) => {
    return !!order.freteId
  }

  const isValidShippingDate = (order: PedidoType) => {
    return !!order.entregaAt || order.entregaAt === ''
  }

  const isValidPayment = (order: PedidoType) => {
    return !!order.meioPagamentoId
  }

  const isValidUserDataWithMessage = (user: UserRegisterType) => {
    if(!isValidNome(user.nome)) {
      present({
        buttons: [{ text: 'fechar', handler: () => dismissToaster() }],
        message: 'Você deve digitar nome e sobrenome',
        duration: 5000,
      })
      return false
    }

    if(!isValidEmail(user.email)) {
      present({
        buttons: [{ text: 'fechar', handler: () => dismissToaster() }],
        message: 'Você deve digitar um email válido',
        duration: 5000,
      })
      return false
    }

    if(!isValidTelefone(user.telefone)) {
      present({
        buttons: [{ text: 'fechar', handler: () => dismissToaster() }],
        message: 'Você deve digitar um telefone válido',
        duration: 5000,
      })
      return false
    }

    if(!isValidCpf(user.cpf)) {
      present({
        buttons: [{ text: 'fechar', handler: () => dismissToaster() }],
        message: 'Você deve digitar um cpf válido',
        duration: 5000,
      })
      return false
    }

    return true
  }

  const isValidUserPasswordWithMessage = (user: UserRegisterType, termos: boolean) => {
    if(!isValidSenha(user.password)) {
      present({
        buttons: [{ text: 'fechar', handler: () => dismissToaster() }],
        message: 'A senha deve conter no mínimo 6 caracteres',
        duration: 5000,
      })
      return false
    }

    if (user.password !== user.passwordAgain) {
      present({
        buttons: [{ text: 'fechar', handler: () => dismissToaster() }],
        message: 'As duas senhas estão diferentes, devem ser iguais',
        duration: 5000,
      })
      return false
    }

    if (!termos) {
      present({
        buttons: [{ text: 'fechar', handler: () => dismissToaster() }],
        message: 'Para poder se cadastrar você deve estar de acordo com os termos de uso',
        duration: 5000,
      })
      return false
    }

    return true
  }

  return {
    isValidNome,
    isValidEmail,
    isValidTelefone,
    isValidCpf,
    isValidSenha,
    isValidCep,
    isValidCorreios,
    isValidAddress,
    isValidPedido,
    isValidShipping,
    isValidShippingDate,
    isValidPayment,
    isValidUserDataWithMessage,
    isValidUserPasswordWithMessage,
  };
}
