import axios from 'axios'
import { useCookies } from 'react-cookie'
import { useResetRecoilState } from 'recoil'
import { userPerfilAtom } from '../recoil/atoms/userPerfil'
import { GalleryType } from '../types/Gallery.type'
import { OrderType } from '../types/Order.type'
import { PaymentType } from '../types/Payment.type'
import { PedidoType } from '../types/Pedido.type'
import { ProductType } from '../types/Product.type'
import { ProdutShippingType, ShippingType } from '../types/ShippingType'
import { AddressCorreios, AddressType, UserApi, UserRegisterType } from '../types/UserApi.type'

export function useApi() {
  const [cookies, setCookies] = useCookies(['token', 'vendedor']);
  const resetUser = useResetRecoilState(userPerfilAtom);

  const config = {
    headers: { Authorization: `Bearer ${cookies.token}` }
  }

  const parseProduct = (product) => {
    return {
      ...product,
      price: product.preco
    }
  }
  
  const parseGallery = (gallery) => {
    return {
      ...gallery,
      produtos: gallery.produtos.map(produto => parseProduct(produto))
    }
  }
  
  const hostEndpoint = 'https://agilitati.com.br/api/v5'

  const shouldInvalidateToken = (e) => {
    if(e.response.status === 401) {
      setCookies('token', null)
      resetUser()
    }
    throw(e)
  }
  
  const getAllProducts = async (slug: string): Promise<GalleryType> => {
    const res = await axios.get(`${process.env.REACT_APP_API_ROOT}/galeria/${slug}`)
    const productList = res.data.data[0].produtos.map(product => parseProduct(product))
    const categoryFull = {
      id: res.data.data[0].id,
      nome: res.data.data[0].nome,
      meta: res.data.data[0].meta,
      slug: slug,
      produtos: productList,
    } 
    return categoryFull
  }
  
  const getProductById = async (id: string): Promise<ProductType> => {
    const res = await axios.get(`${process.env.REACT_APP_API_ROOT}/produto/${id}`)
    return parseProduct(res.data.data[0])
  }
  
  const getAllGalleries = async (): Promise<{galleriesList: GalleryType[], version: string | number}> => {
    const res = await axios.get(`${process.env.REACT_APP_API_ROOT}/galerias`)
    const version = res.data.version
    const galleriesList = res.data.data.map(gallery => parseGallery(gallery))
    return {
      galleriesList: galleriesList,
      version: version
    }
  }
  
  const getMyOrders = (): Promise<Array<OrderType>> => {
    return axios.get(`${hostEndpoint}/pedidos`, config).then(res => res.data.data)
      .catch(e => shouldInvalidateToken(e))
  }
  
  const getFacebookUser = async (token: string) => {
    const facebookEndpoint = 'https://graph.facebook.com/4390502064385877?fields=id,name,email,picture&access_token='
  
    const resFace = await axios.get(`${facebookEndpoint}${token}`)
    
    return {
      id: resFace.data.id,
      logged: true,
      nome: resFace.data.name,
      email: resFace.data.email,
      avatar: resFace.data.picture.data.url,
    }
  }
  
  const loginWithToken = (token) => {
    return axios.get(`${hostEndpoint}/cliente/login/token/${token}`)
  }
  
  const authMe = (): Promise<UserApi> => {
    if (cookies.token) {
      return axios.get<UserApi>(`${hostEndpoint}/me`, config).then(user => user.data)
        .catch(e => shouldInvalidateToken(e))
    }
    return Promise.reject('não logado')
  }
  
  const loginSenha = ({telefone, email, senha}: {telefone?: string, email?: string, senha: string}) => {
    return axios.post(`${hostEndpoint}/cliente/login`, {
      email: email,
      senha: senha
    }).then(user => user.data)
  }
  
  const updateUser = (user: UserRegisterType) => {
    return axios.post<{data: UserApi}>(
        `${hostEndpoint}/me`,
        {...user},
        config
      )
      .then(user => user.data.data[0])
      .catch(e => shouldInvalidateToken(e))
  }

  const registerUser = (user: UserRegisterType) => {
    return axios.post(`${hostEndpoint}/cliente/registrar`, {
      ...user,
      vendedor: cookies.vendedor || ''
    })
  }
  
  const generateRecoveryLink = (email: string) => {
    return axios.post(`${hostEndpoint}/cliente/senha/gerar-token`, {email})
  }

  const changePassword = ({senha, token}: {senha: string, token: string}) => {
    return axios.post(`${hostEndpoint}/cliente/senha/alterar/${token}`, {senha})
  }

  const getAddressByCep = (cep: string) => {
    return axios.get<{data: AddressCorreios}>(`${hostEndpoint}/cep/${cep}`)
      .then(res => res.data.data[0])
  }
  
  const saveNewAddress = ({address}: {address: AddressType}) => {
    if(address.id) {
      return axios.post(`${hostEndpoint}/cliente/endereco/${address.id}`, address, config)
        .then(res => res.data)
        .catch(e => shouldInvalidateToken(e))
    } else {
      return axios.post(`${hostEndpoint}/cliente/endereco`, address, config)
        .then(res => res.data)
        .catch(e => shouldInvalidateToken(e))
    }
  }
  
  const deleteAddress = ({addressId}: {addressId: number | null}) => {
    return axios.delete(`${hostEndpoint}/cliente/endereco/${addressId}`, config)
      .then(res => res.data)
      .catch(e => shouldInvalidateToken(e))
  }
  
  const makeAddressDefault = ({address}: {address: AddressType}) => {
    const defaultAddress = {
      ...address,
      default: true
    }
  
    return axios.post(`${hostEndpoint}/cliente/endereco/${address.id}`, defaultAddress, config)
      .then(res => res.data)
      .catch(e => shouldInvalidateToken(e))
  }
  
  const getShipping = (
    {id, itemsFrete}: {id: number, itemsFrete: Array<ProdutShippingType>}): Promise<Array<ShippingType>> => {
    return axios
      .post(`${hostEndpoint}/cliente/endereco/${id}/frete?itens=${JSON.stringify(itemsFrete)}`,
      {},
      config
    )
    .then(res => res.data.data)
    .catch(e => shouldInvalidateToken(e))
  }
  
  const getPaymentMethods = (): Promise<Array<PaymentType>> => {
    return axios.get(`${hostEndpoint}/meiospagamento/list`, config)
      .then(res => res.data.data)
      .catch(e => shouldInvalidateToken(e))
  }
  
  const doOrderRequest = ({pedido}: {pedido: PedidoType}) => {
    return axios.post( `${hostEndpoint}/pedido`, pedido, config )
      .then(res => res.data.data)
      .catch(e => shouldInvalidateToken(e))
  }

  const saveOrderProof = ({pedidoId, form}: {pedidoId: number, form: FormData}) => {
    return axios.post(`${hostEndpoint}/pedido/${pedidoId}/comprovante`, form, config)
      .then(res => res.data.data)
      .catch(e => shouldInvalidateToken(e))
  }

  const searchProduct = ({text = ''}: {text: string}) => {
    return axios.get(`${hostEndpoint}/produtos/busca?q=${text}`)
      .then(res => {
        return res.data.data.map(product => parseProduct(product))
      })
  }

  return {
    getAllProducts,
    getProductById,
    getAllGalleries,
    getMyOrders,
    getFacebookUser,
    loginWithToken,
    authMe,
    loginSenha,
    updateUser,
    getAddressByCep,
    saveNewAddress,
    deleteAddress,
    makeAddressDefault,
    getShipping,
    getPaymentMethods,
    doOrderRequest,
    saveOrderProof,
    registerUser,
    searchProduct,
    generateRecoveryLink,
    changePassword,
  }
}
