import { atom } from "recoil";
import { UserRegisterType } from "../../types/UserApi.type";

export const userRegisterAtom = atom<UserRegisterType>({
  key: 'userRegister',
  default: {
    nome: '',
    email: '',
    telefone: '+5551',
    cpf: '',
    password: '',
    passwordAgain: '',
    seePassword: false,
  },
});