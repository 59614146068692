import React from 'react';
import { telefoneFormatApi } from '../helpers/phone';
import { logoWhatsapp } from "ionicons/icons"
import { WhatsFloating, WhatsIcon } from "../styled/whatsapp-session.styled";
import { userPerfilAtom } from '../recoil/atoms/userPerfil';
import { useRecoilState } from 'recoil';

const WhatsAppFloatingButton: React.FC = () => {
  const [user] = useRecoilState(userPerfilAtom)
  const whatsappPao = "+5551982182121"
  
  const takeNumber = () => {
    return user.vendedor ? telefoneFormatApi(user.vendedor.telefone) : whatsappPao
  }

  return (
    <WhatsFloating
      href={`https://wa.me/${takeNumber()}`}
      target='_blank'
      rel="noreferrer"
    >
      <WhatsIcon size="large" icon={logoWhatsapp}/>
    </WhatsFloating>
  );
};

export default WhatsAppFloatingButton;
