import React from 'react';
import {
  IonButton, IonFooter, IonIcon, IonList, IonTitle, IonToolbar
} from "@ionic/react";
import { useCart } from "react-use-cart";
import { closeOutline, closeSharp } from "ionicons/icons"
import {
  DiscountBox,
  FinishOrderButton, FreeDelivery,
  IonTitleHighlight,
  IonTitleStyled,
  MinimumValueTitle,
  ModalContent, ModalContentHeader, ModalHeader,
  ModalHeaderTitle, ModalWrapper, ToCheckoutWrapper, ToFreeDelivery, ToFreeDeliveryBar
} from "../styled/products-filter.styled"
import CartItemModal from "./CartItemModal";
import { currencyToBrl } from '../helpers/currency';
import { VerticalSpaceMedium } from '../styled/common.styled';
import { useHistory } from 'react-router';

const CartModal: React.FC <{onDismiss: () => void}> = ({onDismiss}) => {
  const { items, cartTotal, totalItems } = useCart()
  const history = useHistory()

  const toCheckout = () => {
    history.replace('/finalizar-compra')
    onDismiss()
  }

  return (
    <ModalWrapper>
      <ModalHeader>
        <ModalHeaderTitle>
          Carrinho
        </ModalHeaderTitle>
        <IonButton size="small" shape="round" onClick={() => onDismiss()}>
          <IonIcon slot="icon-only" md={closeSharp} ios={closeOutline} />
        </IonButton>
      </ModalHeader>

      <ModalContent>
        <ModalContentHeader>
          {totalItems === 0 && "Carrinho Vazio"}
          {totalItems > 0 && "Produtos"}
        </ModalContentHeader>
        <IonList>
          {items.map((item: any) => {
            return (
              <CartItemModal
                key={item.id}
                product={item}
              />
            )
          })}
        </IonList>

        <IonFooter className="ion-no-border">
          <IonToolbar mode='md'>
            {totalItems > 0 &&
              <ToCheckoutWrapper>
                <ToFreeDeliveryBar value={cartTotal} max="250" />
                <ToFreeDelivery>Frete grátis apartir de R$ 250</ToFreeDelivery>
                {cartTotal < 35 && <MinimumValueTitle>Valor minimo de pedido <strong>R$ 35</strong></MinimumValueTitle>}
                
                {cartTotal >= 250 && <FreeDelivery>Você não pagará taxa de entrega!</FreeDelivery> }

                <DiscountBox>
                  <div>
                    <IonTitleStyled>Cesta - R$ {currencyToBrl(cartTotal)}</IonTitleStyled>
                    <IonTitleHighlight>Desconto 10% - R$ {currencyToBrl(cartTotal * 0.1)}</IonTitleHighlight>
                    <IonTitleStyled>Total - R$ {currencyToBrl(cartTotal * 0.9)}</IonTitleStyled>
                  </div>
                  <div>
                    <FinishOrderButton onClick={() => toCheckout()} color="secondary">Comprar</FinishOrderButton>
                  </div>
                </DiscountBox>
                <VerticalSpaceMedium />
              </ToCheckoutWrapper>
            }
          </IonToolbar>
        </IonFooter>
      </ModalContent>

    </ModalWrapper>
  )
}

export default CartModal
