import React, { useState } from "react";
import {
  IonButton, IonIcon, IonInput, IonItem, IonLabel, useIonModal, useIonToast
} from "@ionic/react"
import {
  eyeOffOutline, eyeOffSharp, eyeOutline,
  eyeSharp, logInOutline, logInSharp, personAddOutline, personAddSharp
} from "ionicons/icons"
import { ForgetMyPasswordText, LoginBox, PasswordBox } from "../styled/login.styled";
import { useLogin } from "../hooks/useLogin";
import { useInputValidation } from "../hooks/useInputValidation";
import SignupModal from "./SignupModal";
import LoginRecoverPassword from "./LoginRecoverPassword";
import { off } from "process";

const LoginForm: React.FC <{onDismiss: () => void, showForgot?: boolean, afterLogin?: () => void}> = (
  {onDismiss, showForgot = true, afterLogin}
) => {
  const [present, dismiss] = useIonToast();

  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [seePassword, setSeePassword] = useState(false);
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState(false);
  const { signIn } = useLogin({callBack: onDismiss});

  const { isValidEmail } = useInputValidation();

  const [preset, dismissModal] = useIonModal(SignupModal, {
    onDismiss: () => dismissModal()
  });

  const [presetRecover, dismissRecoverModal] = useIonModal(LoginRecoverPassword, {
    onDismiss: () => dismissRecoverModal()
  });

  const tooglePassword = () => {
    setSeePassword(!seePassword);
  }

  const validateEmail = () => {
    const validEmail = isValidEmail(email)

    if(!validEmail) {
      present({
        buttons: [{ text: 'fechar', handler: () => dismiss() }],
        message: 'Email inválido',
        duration: 5000,
      })
      setEmailError(true)
      return false
    }
    return true
  }

  const validatePassword = () => {
    if(password.length < 6) {
      present({
        buttons: [{ text: 'fechar', handler: () => dismiss() }],
        message: 'A senha deve ter mais de 6 caracteres',
        duration: 3000,
      })
      setPasswordError(true)
      return false
    }
    return true
  }

  const signInEmailPassword = () => {
    if (validateEmail() && validatePassword()) {
      signIn({email: email, senha: password})
      .then(() => {
        present({
          buttons: [{ text: 'fechar', handler: () => dismiss() }],
          message: 'Login feito com sucesso!',
          duration: 4000,
        })
        if(afterLogin) {
          afterLogin()
        }
      })
      .catch(() => {
        present({
          buttons: [{ text: 'fechar', handler: () => dismiss() }],
          message: 'Login ou senha inválidos',
          duration: 4000,
        })
      })
    }
  }
  
  return (
    <>
      <LoginBox>
        <h3>Entrar com Email e Senha</h3>
        <IonItem>
          <IonLabel position="floating">Email</IonLabel>
          <IonInput
            value={email}
            type="email"
            inputmode="email"
            pattern="email"
            name="email"
            color={emailError ? 'danger' : 'default'}
            onIonChange={e => {
              setEmail(String(e.detail.value))
              setEmailError(false)
            }}
            clearInput 
          />
        </IonItem>
        <PasswordBox>
          <IonLabel position="floating">Senha</IonLabel>
          <IonInput
            value={password}
            color={passwordError ? 'danger' : 'default'}
            autocomplete='current-password'
            onIonChange={e => {
              setPassword(String(e.detail.value))
              setPasswordError(false)
            }}
            type={seePassword ? 'text' : 'password'}
          />
          <IonIcon
            slot='end'
            ios={seePassword ? eyeOffOutline : eyeOutline }
            md={seePassword ? eyeOffSharp : eyeSharp }
            onClick={() => tooglePassword()}
          />
        </PasswordBox>
      </LoginBox>
      <LoginBox>
        <IonButton
          onClick={() => signInEmailPassword()}
          expand="full"
          color="secondary"
          className="ion-margin-top"
        >
          <IonIcon slot="start" ios={logInOutline} md={logInSharp} />
          Entrar
        </IonButton>
        {showForgot && <IonButton
          onClick={() => {
            onDismiss()
            preset()
          }}
          expand='full'
          color="primary"
          className="ion-margin-top"
        >
          <IonIcon slot="start" ios={personAddOutline} md={personAddSharp} />
          Cadastrar
        </IonButton>}
      </LoginBox>
      {showForgot && <ForgetMyPasswordText
        onClick={() => {
          presetRecover()
          onDismiss()
        }}
      >
        Esqueci minha senha
      </ForgetMyPasswordText>}
      {/* <LoginBox>
        <IonButton
          onClick={() => signInFacebook()}
          expand="full"
          color="tertiary"
        >
          <IonIcon slot="start" icon={logoFacebook} />
          Login com Facebook
        </IonButton>
        <IonButton
          onClick={() => signInGoogle()}
          expand="full"
          color="secondary"
          className="ion-margin-top"
        >
          <IonIcon slot="start" icon={logoGoogle} />
          Login com Google
        </IonButton>
      </LoginBox> */}
    </>
  )
}

export default LoginForm
