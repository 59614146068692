import React from "react";
import { IonButton, IonIcon, IonText } from "@ionic/react"
import { closeOutline, closeSharp } from "ionicons/icons"
import { ModalContent, ModalHeader, ModalHeaderTitle, ModalWrapper } from "../styled/products-filter.styled"
import LoginUserData from "./LoginUserData";
import { useRecoilState } from "recoil";
import { userPerfilAtom } from "../recoil/atoms/userPerfil";
import LoginForm from "./LoginForm";

const LoginModal: React.FC<{onDismiss: () => void}> = ({onDismiss}) => {
  const [user] = useRecoilState(userPerfilAtom)
  
  return (
    <ModalWrapper>
      <ModalHeader>
        <ModalHeaderTitle>
          {user.logged ? 'Perfil' : 'Entrar'}
        </ModalHeaderTitle>
        <IonButton size="small" shape="round" onClick={() => onDismiss()}>
          <IonIcon slot="icon-only" md={closeSharp} ios={closeOutline} />
        </IonButton>
      </ModalHeader>

      <ModalContent>
        <IonText color="dark">
          { user.logged ?
            <LoginUserData onDismiss={onDismiss} /> :
            <LoginForm onDismiss={onDismiss} />
          }
        </IonText>
      </ModalContent>
    </ModalWrapper>
  )
}

export default LoginModal
