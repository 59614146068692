import React, { useEffect, useMemo, useState } from 'react';
import { IonHeader, IonIcon, IonToolbar, useIonModal } from '@ionic/react';
import { useCart } from 'react-use-cart';
import { useRecoilState } from 'recoil';
import { bagHandleOutline, bagHandleSharp } from 'ionicons/icons';
import CartModal from '../CartModal';
import { useWindowSize } from '../../hooks/useWindowSize';
import MenuItemsDesk from '../Menu/MenuItemsDesk';
import { appStateAtom } from '../../recoil/atoms/appState';
import { BadgeCartIcon, ClickableItem, HeaderWrapper } from '../../styled/header.styled';
import HeaderSearch from './HeaderSearch';
import HeaderMenuButton from './HeaderMenuButton';
import HeaderLogo from './HeaderLogo';

interface HeaderProps {
  noCart?: boolean
}

const Header: React.FC<HeaderProps> = ({noCart}) => {
  const [appState] = useRecoilState(appStateAtom)
  const { totalUniqueItems } = useCart()
  const size = useWindowSize();
  const [showMenuInline, setShowMenuInline] = useState(size.width > 768)
  const bagIcon = useMemo(() => <IonIcon ios={bagHandleOutline} md={bagHandleSharp} />, [])


  const handleDismiss = () => {
    dismiss();
  };

  const [preset, dismiss] = useIonModal(CartModal, {
    onDismiss: handleDismiss
  });

  useEffect(() => {
    setShowMenuInline(size.width > 768)
  }, [size])

  return (
    <IonHeader>
      <IonToolbar color='primary'>
        <HeaderWrapper>
          {!showMenuInline && <HeaderMenuButton />}
          <HeaderLogo />

          <ClickableItem
            lines='none'
            color='transparent'
            visibility={!noCart}
            onClick={() => preset()}
          >
            {bagIcon}
            {totalUniqueItems > 0 && <BadgeCartIcon animate={appState.animateBasket} color="danger">
              {totalUniqueItems}
            </BadgeCartIcon>}
          </ClickableItem>

          {showMenuInline && <MenuItemsDesk />}
        </HeaderWrapper>

        {/* <HeaderSearch /> */}
      </IonToolbar>
    </IonHeader>
  )
}

export default Header
