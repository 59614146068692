import React from 'react';
import { IonButton, IonIcon } from "@ionic/react"
import { removeOutline, removeSharp } from "ionicons/icons"
import { useCart } from "react-use-cart"
import { useAnimate } from '../../hooks/useAnimate';

const RemoveItem: React.FC<{id: string}> = ({id}) => {
  const { getItem, updateItemQuantity,  } = useCart();
  const { animateBasket } = useAnimate();
  const productQuant = getItem(id).quantity;
  
  return (
    <IonButton size="small" shape="round" mode='md' onClick={() => {
      updateItemQuantity(id, productQuant -1)
      animateBasket()
    }}>
      <IonIcon ios={removeOutline} md={removeSharp} />
    </IonButton>
  )
}

export default RemoveItem
