import { IonBadge, IonIcon, IonItemDivider, IonList } from "@ionic/react";
import styled from "styled-components";

interface ShowAddressProps {
  show: boolean
}

export const CartTitleAddress = styled.span`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--ion-color-secondary);
`

export const IconAddressHome = styled(IonIcon)`
  margin-right: 8px;
`

export const VerticalSpace = styled.div`
  height: 8px;
  width: 100%;
`

export const DeliveryDayText = styled.p`
  display: flex;
  cursor: pointer;
`

export const BadgeDeliveryDay = styled(IonBadge)`
  margin-left: 4px;
`

export const ItemDivider = styled(IonItemDivider)`
  color: var(--ion-color-dark);
  padding-left: 0;
`

export const AddressSelect = styled.div`
  display: flex;
  justify-content: space-between;
`

export const CheckoutList = styled(IonList)`
  /* margin-left: -14px !important; */
`

export const SearchCepWrapper = styled.div`
  margin: 16px auto 0 auto;
  width: 50%;
`

export const FlexBox = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

export const ListAddress = styled(IonList)`
  padding-right: 16px;
`

export const ShowAddress = styled.div<ShowAddressProps>`
  visibility: ${props => props.show ? 'visible' : 'hidden'};
`

export const AddressList = styled(IonList)`
  background-color: #F4F4F4;
`
