import React from 'react';
import { IonContent, IonPage, } from '@ionic/react';
import HeroText from '../components/HeroText';
import Header from '../components/Header/Header';
import WhatsAppSession from '../components/WhatsAppSession';
import ConhecaNossaLoja from '../components/ConhecaNossaLoja';
import Footer from '../components/Footer';
import { usePageName } from 'react-page-name';

const QuemSomos: React.FC = () => {
  usePageName('Quem Somos');

  return (
    <IonPage id="quem-somos">
      <Header noCart />
      <IonContent>
        <div>
          <HeroText
            backgroundImage={'capa1.jpeg'}
            textColor="white"
            height={300}
            background
          />
          <WhatsAppSession />
          <ConhecaNossaLoja />
        </div>
        <Footer home />
      </IonContent>
    </IonPage>
  );
};

export default QuemSomos;
