import {
  bagHandleOutline, bagHandleSharp, 
  receiptOutline, receiptSharp, homeOutline, homeSharp, storefrontOutline, storefrontSharp, 
} from "ionicons/icons";

interface AppPage {
  url: string;
  iosIcon: string;
  mdIcon: string;
  title: string;
}

const appPages: AppPage[] = [
  // {
  //   title: 'Finalizar Compra',
  //   url: '/finalizar-compra',
  //   iosIcon: bagHandleOutline,
  //   mdIcon: bagHandleSharp
  // },
  // {
  //   title: 'Meus Pedidos',
  //   url: '/meus-pedidos',
  //   iosIcon: receiptOutline,
  //   mdIcon: receiptSharp
  // },
  // {
  //   title: 'Quem Somos',
  //   url: '/quem-somos',
  //   iosIcon: roseOutline,
  //   mdIcon: roseSharp
  // },
  {
    title: 'Loja',
    url: '/loja',
    iosIcon: storefrontOutline,
    mdIcon: storefrontSharp
  },
];

export default appPages
