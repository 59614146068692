import { IonItem } from "@ionic/react";
import styled from "styled-components";

export const LoginBox = styled.div`
  margin-top: 24px;
  text-align: center;
`

export const PasswordBox = styled(IonItem)`
  align-items: center;
`

export const ForgetMyPasswordText = styled.p`
  padding-top: 16px;
  text-align: center;
  text-decoration: underline;
  cursor: pointer;
  color: var(--ion-color-tertiary);
`