import React, { useState } from "react"
import { IonButton, IonIcon, IonInput, IonItem, IonLabel, IonList, IonLoading, IonText, IonTextarea, useIonAlert, useIonToast } from "@ionic/react"
import { ModalContent, ModalHeader, ModalHeaderTitle, ModalWrapper } from "../styled/products-filter.styled"
import { closeOutline, closeSharp } from "ionicons/icons"
import { AddressList, SearchCepWrapper, ShowAddress, VerticalSpace } from "../styled/address.styled"
import { useInputValidation } from "../hooks/useInputValidation"
import { useApi } from "../hooks/useApi"
import { AddressCorreios, AddressType } from "../types/UserApi.type"
import { userPerfilAtom } from "../recoil/atoms/userPerfil"
import { useRecoilState } from "recoil"
import { checkoutAtom } from "../recoil/atoms/checkout"

const AddressNewModal: React.FC<{onDismiss: () => void, editingAddress?: AddressType}> = ({onDismiss, editingAddress}) => {
  const { authMe, getAddressByCep, saveNewAddress } = useApi()
  const [, setUser] = useRecoilState(userPerfilAtom);
  const [checkout, setCheckout] = useRecoilState(checkoutAtom)
  const { isValidCep, isValidCorreios } = useInputValidation()
  
  const [present, dismiss] = useIonToast();
  const [ presentAlert ] = useIonAlert();

  const [isLoading, setIsLoading] = useState(false)
  const [searched, setSearched] = useState(editingAddress?.id ? true : false)

  const [nome, setNome] = useState(editingAddress?.nome || 'Minha Casa')
  const [nomeError, setNomeError] = useState(false)
  
  const [cep, setCep] = useState(editingAddress?.cep || '')
  const [cepError, setCepError] = useState(false)

  const [complemento, setComplemento] = useState(editingAddress?.complemento || '')
  const [observacao, setObservacao] = useState(editingAddress?.observacao || '')
  
  const [numero, setNumero] = useState(editingAddress?.numero || '')
  const [numeroError, setNumeroError] = useState(false)

  const [newAddress, setNewAddress] = useState<AddressCorreios>({
    bairro: editingAddress?.bairro || '',
    cep: editingAddress?.cep || '',
    cidade: editingAddress?.cidade || '',
    end: editingAddress?.endereco || '',
    uf: editingAddress?.estado || ''
  })

  const changeCep = () => {
    if(!isValidCep(cep)) {
      setCepError(true)
    }
  }

  const changeNumero = () => {
    if(numero === '') {
      setNumeroError(true)
    }
  }

  const buscarCep = () => {
    if(cepError || cep.length === 0) {
      present({
        buttons: [{ text: 'fechar', handler: () => dismiss() }],
        message: 'Digite um cep válido exemplo: 99999-999',
        duration: 5000,
      })
    } else {
      setIsLoading(true)
      getAddressByCep(cep).then(res => {
        setIsLoading(false)
        setSearched(true)
        setNewAddress({
          bairro: res.bairro,
          cep: res.cep,
          cidade: res.cidade,
          end: res.end,
          uf: res.uf
        })
      })
      .catch(e => {
        setCepError(true)
        setIsLoading(false)
        setSearched(false)
        presentAlert({
          header: 'Ops erro ao consultar frete',
          subHeader: '',
          message: e.response.data.message,
          buttons: ['OK'],
        })
      })
    }
  }

  const saveAddress = () => {
    changeNumero()
    if(!isLoading && searched && isValidCorreios(newAddress) && numero !== '') {
      setIsLoading(true)
      saveNewAddress({address: {
        id: editingAddress?.id || null,
        cep: cep,
        nome: nome,
        bairro: newAddress.bairro,
        cidade: newAddress.cidade,
        endereco: newAddress.end,
        estado: newAddress.uf,
        complemento: complemento,
        numero: numero,
        observacao: observacao,
        tipo: 'entrega',
        default: true,
      }})
      .then(() => {
        setIsLoading(false)
        authMe()
        .then(user => {
          setUser({
            ...user,
            logged: true,
            address: user.entrega || []
          })
          setCheckout({
            ...checkout,
            selectedAddressId: user.entrega.find(a => a.default === true)?.id
          })
        })
      })
      .then(onDismiss)
      .catch(e => {
        setIsLoading(false)
        present({
          buttons: [{ text: 'fechar', handler: () => dismiss() }],
          message: e.response.data.message,
          duration: 5000,
        })  
      })
    } else {
      present({
        buttons: [{ text: 'fechar', handler: () => dismiss() }],
        message: 'Endereço inválido',
        duration: 5000,
      })
    }
  }

  return (
    <ModalWrapper>
      {isLoading &&
        <IonLoading
          isOpen={isLoading}
          onDidDismiss={() => setIsLoading(false)}
          message={'Carregando'}
          duration={5000}
        />
      }

      <ModalHeader>
        <ModalHeaderTitle>
          Adicionar Endereço
        </ModalHeaderTitle>
        <IonButton size="small" shape="round" onClick={() => onDismiss()}>
          <IonIcon slot="icon-only" md={closeSharp} ios={closeOutline} />
        </IonButton>
      </ModalHeader>
    
      <ModalContent>
        <IonText color="dark">
          <IonList>
            <IonItem>
              <IonLabel position="floating">Como deseja chamar esse endereço</IonLabel>
              <IonInput
                value={nome}
                type="text"
                inputmode="text"
                pattern="text"
                color={nomeError ? 'danger' : 'default'}
                onIonChange={e => {
                  setNome(String(e.detail.value))
                  setNomeError(false)
                }}
                clearInput 
              />
            </IonItem>
            <IonItem lines="none">
              <IonLabel position="floating">Cep</IonLabel>
              <IonInput
                value={cep}
                type="text"
                inputmode="text"
                pattern="text"
                color={cepError ? 'danger' : 'default'}
                onIonChange={e => {
                  setCep(String(e.detail.value))
                  setCepError(false)
                }}
                onBlur={() => changeCep()}
                clearInput 
              />
            </IonItem>
          </IonList>
          <SearchCepWrapper>
            <IonButton
              expand="full"
              color="primary"
              disabled={isLoading}
              onClick={() =>  buscarCep()}
            >
              Buscar Cep
            </IonButton>
          </SearchCepWrapper>
          <VerticalSpace />
          <VerticalSpace />
          <AddressList>
            <ShowAddress show={searched && !isLoading}>
              <IonItem>
                <IonLabel position="floating">Cidade</IonLabel>
                <IonInput
                  value={newAddress?.cidade}
                  type="text"
                  inputmode="text"
                  pattern="text"
                  onIonChange={e => {
                    setNewAddress({...newAddress, cidade: String(e.detail.value)})
                  }}
                  clearInput
                />
              </IonItem>
              <IonItem>
                <IonLabel position="floating">Estado</IonLabel>
                <IonInput
                  value={newAddress?.uf}
                  type="text"
                  inputmode="text"
                  pattern="text"
                  onIonChange={e => {
                    setNewAddress({...newAddress, uf: String(e.detail.value)})
                  }}
                  clearInput
                />
              </IonItem>
              <IonItem>
                <IonLabel position="floating">Endereço</IonLabel>
                <IonInput
                  value={newAddress?.end}
                  type="text"
                  inputmode="text"
                  pattern="text"
                  onIonChange={e => {
                    setNewAddress({...newAddress, end: String(e.detail.value)})
                  }}
                  clearInput
                />
              </IonItem>
              <IonItem>
                <IonLabel position="floating">Bairro</IonLabel>
                <IonInput
                  value={newAddress?.bairro}
                  type="text"
                  inputmode="text"
                  pattern="text"
                  onIonChange={e => {
                    setNewAddress({...newAddress, bairro: String(e.detail.value)})
                  }}
                  clearInput
                />
              </IonItem>
              <IonItem>
                <IonLabel
                  position="floating"
                  color={numeroError ? 'danger' : 'default'}
                >
                  Número
                </IonLabel>
                <IonInput
                  value={numero}
                  type="text"
                  inputmode="text"
                  pattern="text"
                  color={numeroError ? 'danger' : 'default'}
                  onIonChange={e => {
                    setNumero(String(e.detail.value))
                    setNumeroError(false)
                  }}
                  onIonBlur={changeNumero}
                  clearInput
                />
              </IonItem>
              <IonItem>
                <IonLabel position="floating">Complemento</IonLabel>
                <IonInput
                  value={complemento}
                  type="text"
                  inputmode="text"
                  pattern="text"
                  onIonChange={e => {
                    setComplemento(String(e.detail.value))
                  }}
                  clearInput
                />
              </IonItem>
              <IonItem lines="none">
                <IonLabel position="floating">Informações adicionais para entrega</IonLabel>
                <IonTextarea
                  value={observacao}
                  onIonChange={e => setObservacao(String(e.detail.value))}>
                </IonTextarea>
              </IonItem>
              <SearchCepWrapper>
                <IonButton
                  expand="full"
                  color="secondary"
                  disabled={isLoading}
                  onClick={() =>  saveAddress()}
                >
                  Salvar endereço
                </IonButton>
              </SearchCepWrapper>
            </ShowAddress>
          </AddressList>
        </IonText>
      </ModalContent>
    </ModalWrapper>
  )
}

export default AddressNewModal
