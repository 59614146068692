import React, { useState } from "react";
import {
  IonButton, IonIcon, IonInput, IonItem, IonLabel, IonLoading, IonText, useIonAlert, useIonModal, useIonToast
} from "@ionic/react"
import { closeOutline, closeSharp, logInOutline, logInSharp, mailOutline, mailSharp, personAddOutline, personAddSharp } from "ionicons/icons"
import { LoginBox } from "../styled/login.styled";
import { useInputValidation } from "../hooks/useInputValidation";
import SignupModal from "./SignupModal";
import LoginModal from "./LoginModal";
import { ModalContent, ModalHeader, ModalHeaderTitle, ModalWrapper } from "../styled/products-filter.styled";
import { VerticalSpaceMedium } from "../styled/common.styled";
import { useApi } from "../hooks/useApi";

const LoginRecoverPassword: React.FC <{onDismiss: () => void}> = ({onDismiss}) => {
  const { isValidEmail } = useInputValidation();
  const [ presentAlert ] = useIonAlert();
  const [present, dismiss] = useIonToast();
  const { generateRecoveryLink } = useApi()

  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  


  const [presentSignup, dismissSignupModal] = useIonModal(SignupModal, {
    onDismiss: () => dismissSignupModal()
  });

  const [presentLogin, dismissLoginModal] = useIonModal(LoginModal, {
    onDismiss: () => dismissLoginModal()
  });

  const validateEmail = () => {
    const validEmail = isValidEmail(email)

    if(!validEmail) {
      present({
        buttons: [{ text: 'fechar', handler: () => dismiss() }],
        message: 'Email inválido',
        duration: 5000,
      })
      setEmailError(true)
      return false
    }
    return true
  }

  const RecoveryPassword = () => {
    if(validateEmail()) {
      setIsLoading(true)

      generateRecoveryLink(email)
        .then(res => {
          setIsLoading(false)
          presentAlert({
            header: 'Email Enviado!',
            subHeader: '',
            message: res.data.message,
            buttons: ['OK'],
          })
        })
        .catch(e => {
          setIsLoading(false)
          presentAlert({
            header: 'Houve um erro ao recuperar a senha',
            subHeader: '',
            message: e.response.data.message,
            buttons: ['OK'],
          })
        })
    }
  }
  
  return (
    <ModalWrapper>
      {isLoading &&
        <IonLoading
          isOpen={isLoading}
          onDidDismiss={() => setIsLoading(false)}
          message={'Enviando Email'}
          duration={5000}
        />
      }

      <ModalHeader>
        <ModalHeaderTitle>
          Recuperar Senha
        </ModalHeaderTitle>
        <IonButton size="small" shape="round" onClick={() => onDismiss()}>
          <IonIcon slot="icon-only" md={closeSharp} ios={closeOutline} />
        </IonButton>
      </ModalHeader>

      <ModalContent>
        <IonText color="dark">
          <LoginBox>
            <h3>Informe seu Email</h3>
            <IonItem>
              <IonLabel position="floating">Email</IonLabel>
              <IonInput
                value={email}
                type="email"
                inputmode="email"
                pattern="email"
                name="email"
                color={emailError ? 'danger' : 'default'}
                onIonChange={e => {
                  setEmail(String(e.detail.value))
                  setEmailError(false)
                }}
                clearInput 
              />
            </IonItem>
          </LoginBox>
          <LoginBox>
            <IonButton
              onClick={RecoveryPassword}
              expand="full"
              color="black"
              className="ion-margin-top"
            >
              <IonIcon slot="start" ios={mailOutline} md={mailSharp} />
                Recuperar senha
            </IonButton>
            <p>
              Sera enviado um link de recuperação de senha para o seu email cadastrado.
              Após informar o email você deve verificar o seu email!
            </p>
            <VerticalSpaceMedium />
            <IonButton
              onClick={() => {
                onDismiss()
                presentLogin()
              }}
              expand="full"
              color="secondary"
              className="ion-margin-top"
            >
              <IonIcon slot="start" ios={logInOutline} md={logInSharp} />
              Já tenho uma conta
            </IonButton>
            <IonButton
              onClick={() => {
                onDismiss()
                presentSignup()
              }}
              expand='full'
              color="primary"
              className="ion-margin-top"
            >
              <IonIcon slot="start" ios={personAddOutline} md={personAddSharp} />
              Cadastrar
            </IonButton>
          </LoginBox>
        </IonText>
      </ModalContent>
    </ModalWrapper>
  )
}

export default LoginRecoverPassword
