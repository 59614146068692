import { IonApp, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { CartProvider } from 'react-use-cart'
import { RecoilRoot } from 'recoil'
import { CookiesProvider } from 'react-cookie';
import { Helmet } from 'react-helmet';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* carousel styles */
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

/* Theme variables */
import './theme/variables.css';

import LoginProvider from './components/LoginProvider';
import PageRouter from './PageRouter';
import WhatsAppFloatingButton from "./components/WhatsAppFloatingButton";
import React from "react";

setupIonicReact();

const App: React.FC = () => {
  return (
    <IonReactRouter>
      <CookiesProvider>
        <CartProvider>
          <RecoilRoot>
            <LoginProvider>
              <IonApp>
                <PageRouter />
                <Helmet>
                  <meta name="title" content="Pão na Porta, produtos feitos com carinho na comunidade" />
                  <meta name="description" content="Pães de fermentação natural, queijos, doces, frutas e verduras e muito mais!" />
                  <meta property="og:title" content="Pão na Porta, produtos feitos com carinho na comunidade" />
                  <meta property="og:description" content="Pães de fermentação natural, queijos, doces, frutas e verduras e muito mais!" />
                  <meta property="og:url" content="https://queropaonaporta.com.br/" />
                  <meta property="og:image" content="https://queropaonaporta.com.br/assets/images/pao-na-porta.jpg" />
                  <meta property="og:image:alt" content="Pães de fermentação natural, queijos, doces, frutas e verduras e muito mais!" />
                  <meta property="og:image:width" content="320" />
                  <meta property="og:image:height" content="320" />
                </Helmet>
                <WhatsAppFloatingButton />
              </IonApp>
            </LoginProvider>
          </RecoilRoot>
        </CartProvider>
      </CookiesProvider>
    </IonReactRouter>
  );
};

export default App;
