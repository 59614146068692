import React, { memo } from 'react';
import { HeaderLogoWrapper, HomeLink } from '../../styled/header.styled';

const HeaderLogo: React.FC = () => {
  return (
    <HeaderLogoWrapper size="small">
      <HomeLink to="/" >
        <img src={`${process.env.PUBLIC_URL}/assets/images/logo-black.png`} />
      </HomeLink>
    </HeaderLogoWrapper>
  )
}

export default memo(HeaderLogo)
