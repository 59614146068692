import { atom } from "recoil";
import { UserPerfilType } from "../../types/UserApi.type";

export const userPerfilAtom = atom<UserPerfilType>({
  key: 'user',
  default: {
    id: '',
    logged: false,
    nome: '',
    email: '',
    cpf: null,
    telefone: '',
    faceToken: '',
    googleToken: '',
    avatar: '',
    address: [],
    vendedor: undefined,
  },
});