import { IonIcon } from "@ionic/react";
import styled from "styled-components";

export const WhatsappWrapper = styled.div`
  width: 100%;
  padding: 16px;
  padding-bottom: 40px;
  background-color: var(--ion-color-light);
  color: var(--ion-color-dark);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const WhatsContato = styled.a`
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    font-size: 24px;
  }
`
export const WhatsFloating = styled.a`
  position:fixed;
  width:60px;
  height:60px;
  bottom:20px;
  right:20px;
  background-color:#25d366;
  color:#FFF;
  border-radius:50px;
  text-align:center;
  font-size:30px;
  box-shadow: 2px 2px 3px #999;
  z-index:100;
  display: flex;
  align-items: center;
  justify-content: center;
  
  ion-icon {
    margin-right: 0!important;
    margin-top: -3px;
    margin-left: 2px;
  }
  span {
    font-size: 24px;
  }
`

export const WhatsIcon = styled(IonIcon)`
  margin-right: 8px;
`
