import { IonButton, IonCardHeader, IonCardSubtitle, IonCardTitle, IonImg } from "@ionic/react";
import { Link } from "react-router-dom";
import styled from "styled-components";

interface WrapperBuyMoreProps {
  inline?: boolean
}

interface PriceTextProps {
  margin?: boolean
}

interface ImageInsideProps {
  margin?: boolean
}

export const WrapperBuyMore = styled.div<WrapperBuyMoreProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  min-height: 44px;
  /* border: solid 1px var(--ion-color-secondary); */

  ${props => {
    if (props.inline) {
      return (
        `
        @media(max-width: 767px) {
          flex-direction: column-reverse;
          padding: 8px 0px;
        }
        `
      )
    }
  }}
`

export const ButtonWrapper = styled.div`
  width: 20px;
  height: 20px;
  overflow: hide;
`

export const ImageWrapper = styled.div`
  height: auto;
  display: flex;
  justify-content: center;
`

export const RoundImage = styled(IonImg)<ImageInsideProps>`
  border-radius: 4px;
  overflow: hidden;
  width: 100%;
  margin: ${props => props.margin ? '8px' : 0};
`

export const Title = styled(IonCardTitle)`
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical; 

  font-size: 18px;
  height: 42px;

  @media(max-width: 768px) {
    font-size: 14px;
    height: 34px;
  }
`

export const PriceText = styled(Title)<PriceTextProps>`
  height: 24px;

  ${props => {
    if(props.margin) {
      return (`
        margin-top: 21px;
        @media(max-width: 768px) {
          margin-top: 19.5px;
        }
      `)
    }
  }}
`

export const LastUnits = styled(IonCardSubtitle)`
  height: 20px;
  color: var(--ion-color-secondary);
  font-weight: bold;
  font-size: 12px;
  
  @media(max-width: 768px) {
    font-size: 11px;
  }
`

export const OldPrice = styled(IonCardSubtitle)`
  text-decoration: line-through;
  
  @media(max-width: 768px) {
    font-size: 13px;
  }
`

export const CardHeaderNoPadding = styled(IonCardHeader)`
  padding-bottom: 0;
`

export const CountItems = styled.span`
  margin: 0 8px;
  font-size: 20px;
  font-weight: bold;
  color: var(--ion-color-tertiary);
`

export const ToProduct = styled(Link)`
  text-decoration: none;
`

export const BuyButtonWeight = styled(IonButton)`
  font-weight: 900;
  color: white;
`

