import { atom } from "recoil";
import { CheckoutType } from "../../types/Checkout.type";

export const checkoutAtom = atom<CheckoutType>({
  key: 'checkout',
  default: {
    selectedAddressId: 0,
    selectedShippingDate: '',
    selectedShipping: undefined,
    selectedPayment: 0,
    needChange: false,
    changeFor: '',
    OrderObs: '',
  },
});
