import React from 'react';
import { IonContent, IonPage, } from '@ionic/react';
import HeroText from '../components/HeroText';
import Header from '../components/Header/Header';
import ConhecaNossaLoja from '../components/ConhecaNossaLoja';
import Footer from '../components/Footer';
import { usePageName } from 'react-page-name';

const Loja: React.FC = () => {
  usePageName('Loja');

  return (
    <IonPage id="quem-somos">
      <Header noCart />
      <IonContent>
        <div>
          <ConhecaNossaLoja />
        </div>
        <Footer home />
      </IonContent>
    </IonPage>
  );
};

export default Loja;
