import styled from "styled-components";
import PhoneInput from 'react-phone-number-input'
import { IonButton, IonIcon, IonItem } from "@ionic/react";

interface PhoneInputCustomProps {
  $error: boolean
}

export const PrimeiroAcessoWrapper = styled.div`
  padding: 24px;
`

export const Title = styled.h1`
  text-align: center;
`

export const PhonePlaceholder = styled.span`
  margin-left: 12px;
  font-size: 12px;
`

export const PhoneInputCustom = styled(PhoneInput)<PhoneInputCustomProps>`
  input {
    background-color: transparent;
    border: none;
    outline: none;
    height: 60px;
  }
  
  .PhoneInputCountry {
    margin-left: 16px;
  }

  color: ${props => props.$error ? 'red' : 'inherit'};
`

export const IonItemPhone = styled(IonItem)`
  --padding-start: 0;
`

export const FullScreen = styled.div`
  min-height: 100vh;
`

export const HeroIcon = styled(IonIcon)`
  font-size: 270px;
  margin-top: 40px;
  color: var(--ion-color-primary);
`

export const Continuar = styled(IonButton)`
  margin-top: 24px;
  font-weight: bold;
`

export const FinishBox = styled.div`
  padding: 24px;

  h2 {
    text-align: center;
  }
`

export const HeroIconCenter = styled(IonIcon)`
  text-align: center;
  font-size: 270px;
  margin-top: 40px;
  width: 100%;
  color: var(--ion-color-primary);
`

export const LogoImg = styled.img`
  max-width: 350px !important;
  margin: 24px auto 0 auto;
  border: 0;
  display: block;
`
