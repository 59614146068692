import { IonButton } from "@ionic/react"
import { CatalogoLoja, ImgFullWrapper, LocationIcon, LojaConteudo, LojaWrapper } from "../styled/conheca-nossa-loja"
import { bagOutline, bagSharp, locationOutline, locationSharp } from "ionicons/icons"

const ConteudoLoja = () => {
  const loja1 = `${process.env.PUBLIC_URL}/assets/images/loja-dentro.jpeg`
  const loja2 = `${process.env.PUBLIC_URL}/assets/images/lanche-geral.jpeg`
  const loja3 = `${process.env.PUBLIC_URL}/assets/images/lanche-de-cima.jpeg`

  return (
    <>
      {/* <LojaWrapper>
        <div>
          <h2>Peça e receba ainda hoje pela Loja</h2>
          <a href="https://pao-na-porta.goomer.app/" target='_blank' rel="noreferrer">
            <IonButton>
              <LocationIcon size="medium" md={bagSharp} ios={bagOutline} />
              <span>Ver catálogo</span>
            </IonButton>
          </a>
        </div>
      </LojaWrapper> */}
      <LojaConteudo>
        <ImgFullWrapper image={loja1} />
        <CatalogoLoja>
          <h3>
            Com o encerramento do projeto de entregas a domicílio, que funcionou de 2020 ao final de 2023, o café
            do <strong>Pão na Porta</strong> também vai atender a demanda de quem quer continuar comprando e consumindo alimentos de
            qualidade e sem veneno. Todos os produtos produzidos na padaria própria vão continuar sendo vendidos no café.
          </h3>
          <h3>
            O <strong>Pão na Porta</strong> tem dois ambientes externos, um no primeiro andar, onde é possível ir e levar o pet e, no
            segundo andar, onde há uma varanda, especial para os dias amenos em Porto Alegre. Outra atração são as pipas
            de vinho, decorativas, uma na fachada do café e outra no segundo andar, na parte interna.
            Os espaços podem ser reservados para eventos, reuniões, formaturas, aniversários, entre outros.
          </h3>
          <a href="https://goo.gl/maps/QEs7p8yy4uxjhyzLA" target='_blank' rel="noreferrer">
            <IonButton>
              <LocationIcon size="medium" md={locationSharp} ios={locationOutline} />
              <span>Como Chegar</span>
            </IonButton>
          </a>
        </CatalogoLoja>
      </LojaConteudo>

      <LojaConteudo invertContent>
        <CatalogoLoja>
          <h3>
            O café <strong>Pão na Porta</strong> é um espaço lindo e aconchegante na Cidade Baixa em Porto Alegre.
            Famoso pelos seus clássicos <strong>pães de fermentação natural, lanches, cafés e sobremesas</strong>, o
            <strong> Pão na Porta</strong> te recebe com a melhor trilha sonora da cidade baixa pra curtir o momento da parada para o café.
          </h3>
          <h3>
            Entre os destaques do cardápo estão os pães de fermentação natural e sem glúten; a tábua de café colonial;
            o suco de morango feito com morango sem veneno plantado na comunidade; o açaí vindo diretamente do Pará, sem
            conservantes e com sabor de verdade, servido com farinha de tapioca; e os sanduíches especiais produzidos
            somente no Pão na Porta, chamados Tântrico, Mata Carência, Orgástico, Quase uma Transa e o Prazer é todo meu.
          </h3>
        </CatalogoLoja>
        <ImgFullWrapper image={loja2} />
      </LojaConteudo>

      <LojaConteudo>
        <ImgFullWrapper image={loja3} />
        <CatalogoLoja>
          <h3>
            O Pão na Porta é um projeto coletivo criado, há 10 anos, na comunidade Osho Rachana.
            A comunidade é um sítio onde moram 60 amigos no interior de Viamão, que optam por uma alimentação
            saudável e sem veneno e trabalham também com terapia e autoconhecimento. O Pão chega na sua terceira
            reformulação de sua existência neste começo de 2024.
          </h3>
          <h3>
            De 2020 a 2023, durante e um pouco após a pandemia, o Pão na Porta funcionou como um projeto de entrega
            a domicílio de pães, hortaliças sem veneno, cosméticos naturais e cerâmicas artesanais, onde todos os
            moradores da comunidade estavam envolvidos em todas as etapas: desde a produção, passando pelas
            embalagens, colheita das verduras, finalização das cerâmicas e organização e acompanhamento das entregas.
            No final de 2022, o Pão na Porta inaugurou seu café.
          </h3>
        </CatalogoLoja>
      </LojaConteudo>
    </>
  )
}

export default ConteudoLoja
