import styled from 'styled-components';
const imageRoot = `${process.env.PUBLIC_URL}/assets/images/`

interface HeroWrapperProps {
  backgroundImage?: string
  height?: number
}

interface TextColor {
  textColor?: string
}

interface HeroTextWrapperProps {
  background?: boolean
}

export const Title = styled.h1<TextColor>`
  text-transform: uppercase;
  text-align: center;
  color: ${props => props.textColor ? props.textColor : 'var(--ion-text-color, #424242)'};
`

export const SubTitle = styled.h2<TextColor>`
  text-align: center;
  font-size: 16px;
  color: ${props => props.textColor ? props.textColor : 'var(--ion-text-color, #424242)'};
`

export const HeroTextWrapper = styled.div<HeroTextWrapperProps>`
  ${props => {
    if (props.background) {
      return `
        background-color: rgba(10,23,55,0.5);
        width: 100%;
      `
    }
  }}
`

export const HeroWrapper = styled.div<HeroWrapperProps>`
  display: flex;
  align-items: end;
  justify-content: center;

  ${props => {
    if (props.backgroundImage) {
      return `
        background-image: url(${`${imageRoot}${props.backgroundImage}`});
        background-size: cover;
        background-position: center;
        color: white;
      `
    }
  }}

  ${props => {
    if (props.height) {
      return `
        min-height: ${props.height}px;
      `
    }
  }}
`
