import React from 'react';
import { FooterWrapper } from "../styled/footer.styled";

const Footer: React.FC<{home?: boolean}> = ({home = false}) => {
  return (
    <FooterWrapper home>
      Copyrights © 2022 | Pão na Porta | <a href="termos-de-uso"> Termos de Uso </a> | <a href="politicas-de-privacidade"> Política de Privacidade </a>
    </FooterWrapper>
  );
};

export default Footer;
