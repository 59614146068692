import React, { useState } from 'react';
import { useRecoilState } from 'recoil';
import { IonInput, IonItem, IonLabel } from '@ionic/react';
import { useInputValidation } from '../hooks/useInputValidation';
import { userRegisterAtom } from '../recoil/atoms/userRegister';

import { IonItemPhone, PhoneInputCustom } from '../styled/primeiro-acesso.styled';

import 'react-phone-number-input/style.css';

const UserDadosForm: React.FC = () => {
  const [user, setUser] = useRecoilState(userRegisterAtom)

  const { isValidNome, isValidEmail, isValidTelefone, isValidCpf } = useInputValidation();

  const [nomeError, setNomeError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [telefoneError, setTelefoneError] = useState(false);
  const [cpfError, setCpfError] = useState(false);
  
  const changeName = () => {
    if(isValidNome(user.nome)) {
      setNomeError(false)
    } else {
      setNomeError(true)
    }
  }

  const changeEmail = () => {
    if(isValidEmail(user.email)) {
      setEmailError(false)
    } else {
      setEmailError(true)
    }
  }

  const changeTelefone = () => {
    setTelefoneError(!isValidTelefone(user.telefone)) 
  }

  const changeCpf = () => {
    setCpfError(!isValidCpf(user.cpf))
  }

  return (
    <>
      <IonItem>
        <IonLabel position="floating">Nome</IonLabel>
        <IonInput
          value={user.nome}
          inputmode="text"
          pattern="text"
          autocomplete='name'
          color={nomeError ? 'danger' : 'default'}
          onIonChange={e => {
            setUser({...user, nome: String(e.detail.value)})
            setNomeError(false)
          }}
          onIonBlur={() => changeName()}
          type="text"
          clearInput 
        />
      </IonItem>
      <IonItem>
        <IonLabel position="floating">Email</IonLabel>
        <IonInput
          value={user.email}
          inputmode="email"
          pattern="email"
          autocomplete='email'
          color={emailError ? 'danger' : 'default'}
          onIonChange={e => {
            setUser({...user, email: String(e.detail.value)})
            setEmailError(false)
          }}
          onBlur={() => changeEmail()}
          type="email"
          clearInput 
        />
      </IonItem>
      <IonItemPhone>
        <PhoneInputCustom
          placeholder="Telefone"
          defaultCountry="BR"
          international
          value={user.telefone}
          $error={telefoneError}
          onBlur={() => changeTelefone()}
          onChange={(e) => {
            setUser({...user, telefone: e})
            setTelefoneError(false)
          }}
        />
      </IonItemPhone>
      <IonItem>
        <IonLabel position="floating">CPF</IonLabel>
        <IonInput
          value={user.cpf}
          inputmode="text"
          pattern="text"
          type="text"
          color={cpfError ? 'danger' : 'default'}
          autocomplete='off'
          onIonChange={e => {
            setUser({...user, cpf: String(e.detail.value)})
            setCpfError(false)
          }}
          onBlur={() => changeCpf()}
          clearInput 
        />
      </IonItem>
    </>
  );
};

export default UserDadosForm;
