import React from 'react';
import { IonItem, IonLabel } from "@ionic/react"
import { CartItemTitle, Thumbnail } from "../styled/products-filter.styled"
import { ProductType } from "../types/Product.type"
import { BuyButton } from "./BuyButton/BuyButton"
import { currencyToBrl } from '../helpers/currency';
import { thumbImage } from '../helpers/products';

interface CartItemModalProps {
  product: ProductType
}

const CartItemModal: React.FC<CartItemModalProps> = ({product}) => {
  return (
    <IonItem>
      <Thumbnail>
        <img alt={product.nome} src={thumbImage(product)} />
      </Thumbnail>
      <IonLabel>
        <CartItemTitle>
          {product.nome}
        </CartItemTitle>
        <p>
          R$ {currencyToBrl(product.preco)}
        </p>
      </IonLabel>
      <BuyButton product={product}/>
    </IonItem>
  )
}

export default CartItemModal
