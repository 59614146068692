import React, { useState } from 'react';
import { useRecoilState } from 'recoil';
import { IonIcon, IonInput, IonLabel } from '@ionic/react';
import { PasswordBox } from '../styled/login.styled';

import 'react-phone-number-input/style.css';
import { eyeOffOutline, eyeOffSharp, eyeOutline, eyeSharp
} from 'ionicons/icons';
import { useInputValidation } from '../hooks/useInputValidation';
import { userRegisterAtom } from '../recoil/atoms/userRegister';

const UserPasswordForm: React.FC = () => {
  const [user, setUser] = useRecoilState(userRegisterAtom)

  const { isValidSenha } = useInputValidation();

  const [passwordError, setPasswordError] = useState(false);

  const changePassword = () => {
    setPasswordError(!isValidSenha(user.password))
  }

  return (
    <>
      <PasswordBox>
        <IonLabel position="floating">Senha</IonLabel>
        <IonInput
          value={user.password}
          color={passwordError ? 'danger' : 'default'}
          autocomplete='current-password'
          onIonChange={e => {
            setUser({...user, password: String(e.detail.value)})
            setPasswordError(false)
          }}
          onBlur={() => changePassword()}
          type={user.seePassword ? 'text' : 'password'}
        />
        <IonIcon
          slot='end'
          ios={user.seePassword ? eyeOffOutline : eyeOutline }
          md={user.seePassword ? eyeOffSharp : eyeSharp }
          onClick={() => setUser({...user, seePassword: !user.seePassword })}
        />
      </PasswordBox>
      <PasswordBox>
        <IonLabel position="floating">Repetir Senha</IonLabel>
        <IonInput
          value={user.passwordAgain}
          color={passwordError ? 'danger' : 'default'}
          onIonChange={e => {
            setUser({...user, passwordAgain: String(e.detail.value)})
            setPasswordError(false)
          }}
          type={user.seePassword ? 'text' : 'password'}
        />
        <IonIcon
          slot='end'
          ios={user.seePassword ? eyeOffOutline : eyeOutline }
          md={user.seePassword ? eyeOffSharp : eyeSharp }
          onClick={() => setUser({...user, seePassword: !user.seePassword })}
        />
      </PasswordBox>
    </>
  );
};

export default UserPasswordForm;
