import { IonButton, IonIcon, IonSelect, IonThumbnail, IonTitle } from "@ionic/react";
import styled from "styled-components";

export const FilterWrapper = styled.div`
  padding: 16px 16px 4px 16px;
` 

export const FilterLine = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`

export const FilterLink = styled.span`
  display: flex;
  align-items: center;
`

export const LabelWithIcon = styled.div`
  display: flex;
  align-items: center;
`

export const CleanFilter = styled.span`
  font-size: 13px;
  margin-left: 8px;
  color: var(--ion-color-secondary);
`

export const FilterIcon = styled(IonIcon)`
  margin-right: 8px;
`

export const OrderSelect = styled(IonSelect)`
  padding-left: 0;
`

export const ModalWrapper = styled.div`
  padding: 16px;
  overflow: scroll;
  background-color: #f4f4f4;
`

export const ToCheckoutWrapper = styled.div`
  min-height: 60px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`

export const MinimumValueTitle = styled.h6`
  width: 100%;
  text-align: center;
`

export const ToFreeDelivery = styled.h6`
  width: 100%;
  text-align: center;
  font-weight: bold;
  color: var(--ion-color-info);
`

export const ToFreeDeliveryBar = styled.progress`
  border-radius: 12px;
  margin-top: 8px;
  width: 90%;
  transition: 1s width ease;
  background: var(--ion-color-tertiary);

  &::-webkit-progress-value {
    border-radius: 12px;
    transition: 1s width ease;
    background: var(--ion-color-primary);
  }

  &::-webkit-progress-bar {
    border-radius: 12px;
    transition: 1s width ease;
    background: var(--ion-color-light);
  }

  &::-moz-progress-value {
    border-radius: 12px;
    transition: 1s width ease;
    background: var(--ion-color-primary);
  }

  &::-moz-progress-bar {
    border-radius: 12px;
    transition: 1s width ease;
    background: var(--ion-color-light);
  }
`

export const FreeDelivery = styled.h6`
  width: 100%;
  text-align: center;
  font-weight: bold;
  color: var(--ion-color-success);
`

export const FinishOrderButton = styled(IonButton)`
  margin-right: 20px;
`

export const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--ion-color-secondary);
  margin: -16px;
  padding: 16px;
`

export const ModalContent = styled.div`
  margin-top: 40px;
`

export const Thumbnail = styled(IonThumbnail)`
  margin-right: 8px;
  border-radius: 4px;
  overflow: hidden;
`

export const ModalContentHeader = styled.h3`
  color: var(--ion-color-secondary);
`

export const ModalHeaderTitle = styled.h4`
  padding: 0;
  margin: 0;
  color: var(--ion-color-light);
`


export const CartItemTitle = styled.h3`
  white-space: pre-wrap;
`

export const DiscountBox = styled.div`
  flex-wrap: wrap;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const IonTitleStyled = styled(IonTitle)`
  margin-bottom: 8px;
`

export const IonTitleHighlight = styled(IonTitle)`
  color: var(--ion-color-secondary);
  margin-bottom: 8px;
  font-weight: 700;
`