export const telefoneFormatApi = (telefone) => {
  telefone = String(telefone)
  if (telefone) {
    telefone = telefone.replaceAll(' ', '').replaceAll('-','')

    if(telefone.length <= 11) {
      return `+55${telefone}`
    }
    if(telefone.includes('+')) {
      return telefone
    }
    return `+${telefone}`
  }
  return telefone
}
