import React from 'react';
import { IonButton, IonCardHeader, IonCardSubtitle, IonIcon, useIonModal } from "@ionic/react"
import {  CartTitleAddress, IconAddressHome } from "../styled/address.styled"
import AddressNewModal from './AddressNewModal';
import { addCircleOutline, addCircleSharp } from 'ionicons/icons';

interface AddressCardHeaderProps {
  title: string,
  iosIcon: string,
  mdIcon: string,
  addNew?: boolean
}

const AddressCardHeader: React.FC<AddressCardHeaderProps> = ({title, iosIcon, mdIcon, children, addNew = false}) => {
  const handleDismiss = () => {
    dismiss();
  };

  const [preset, dismiss] = useIonModal(AddressNewModal, {
    onDismiss: handleDismiss
  });
  
  return (
    <IonCardHeader>
      <IonCardSubtitle>
        <CartTitleAddress>
          <span>
            <IconAddressHome ios={iosIcon} md={mdIcon} color="secondary"/>
            {title}
          </span>
          {addNew && <IonButton
            fill='outline'
            size='small'
            color='medium'
            onClick={() => preset()}
          >
            <IonIcon slot="start" ios={addCircleOutline} md={addCircleSharp} />
            Adicionar
          </IonButton>}
          {children}
        </CartTitleAddress>
      </IonCardSubtitle>
    </IonCardHeader>
  )
}

export default AddressCardHeader
