import React from 'react';
import { IonAccordionGroup, IonItem, IonLabel, IonMenuToggle } from "@ionic/react";
import { useRecoilState } from 'recoil';
import { nutritionSharp } from 'ionicons/icons';
import { useLocation } from "react-router";
import { IonAccordionCustom, MenuItem } from "../../styled/menu.styled";
import { galeriesState } from '../../recoil/atoms/galeries';

const MenuItemsGalleries: React.FC = () => {
  const [galleries] = useRecoilState(galeriesState);
  const location = useLocation()

  return (
    <IonAccordionGroup>
      <IonAccordionCustom toggleIcon={nutritionSharp} mode="md" toggleIconSlot="start">
        {/* <IonItem slot="header">
          <IonLabel>Produtos</IonLabel>
        </IonItem> */}
        {galleries.map((gallery) => {
          return (
            <IonMenuToggle key={gallery.id} autoHide={false} slot="content">
              <MenuItem
                routerLink={`/catalogo/${gallery.slug}`}
                routerDirection="none"
                lines="none"
                detail={false}
                selected={location.pathname.includes(gallery.slug) ? true : false}
              >
                <IonLabel>{gallery.nome}</IonLabel>
              </MenuItem>
            </IonMenuToggle>
          );
        })}
      </IonAccordionCustom>
    </IonAccordionGroup>
  )
}

export default MenuItemsGalleries
